import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { useSession, useUser } from "@clerk/clerk-react";
import { SignedIn, SignedOut, UserButton } from "@clerk/clerk-react";
import axios from 'axios';
import '../css/nav.css';
import config from '../config';

const API_BASE_URL = config.API_BASE_URL;

const Navbar: React.FC<{ onSignUpClick: () => void }> = ({ onSignUpClick }) => {
  const [showMenu, setShowMenu] = useState(false);
  const { session } = useSession();
  const { user } = useUser();
  const [membershipType, setMembershipType] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  const calculateMenuHeight = useCallback(() => {
    const navLinks = document.querySelector('.nav-links');
    if (navLinks) {
      const linkCount = navLinks.children.length;
      const linkHeight = 60;
      const padding = 40;
      return linkCount * linkHeight + padding + 40;
    }
    return 0;
  }, []);

  const toggleMenu = useCallback(() => {
    setShowMenu((prevShowMenu) => {
      const newShowMenu = !prevShowMenu;
      document.body.classList.toggle('menu-open', newShowMenu);
      if (newShowMenu && window.innerWidth <= 768) {
        const menuHeight = calculateMenuHeight();
        document.body.style.paddingTop = `${menuHeight}px`;
      } else {
        document.body.style.paddingTop = '0';
      }
      return newShowMenu;
    });
  }, [calculateMenuHeight]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setShowMenu(false);
        document.body.classList.remove('menu-open');
        document.body.style.paddingTop = '0';
      } else if (showMenu) {
        const menuHeight = calculateMenuHeight();
        document.body.style.paddingTop = `${menuHeight}px`;
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [showMenu, calculateMenuHeight]);

  useEffect(() => {
    // Reset padding and scroll to top when location changes
    document.body.style.paddingTop = '0';
    window.scrollTo(0, 0);
  }, [location]);

  const saveUserToMongoDB = useCallback(async () => {
    if (user && session) {
      try {
        const token = await session.getToken();
        const response = await axios.post(`${API_BASE_URL}/api/users/auth/user`, {
          clerkId: user.id,
          username: user.username || 'defaultUsername',
          email: user.primaryEmailAddress?.emailAddress || 'defaultEmail@example.com',
          acceptedTOS: true,
          acceptedPrivacyPolicy: true
        }, {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        });
        return response.data;
      } catch (error) {
        console.error('Error creating user in MongoDB');
        throw error;
      }
    }
  }, [user, session]);

  const fetchUserProfile = useCallback(async () => {
    if (session && user) {
      try {
        const token = await session.getToken();
        const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        });
        
        const { membershipType, cancellationDate } = response.data;
        
        if (cancellationDate && cancellationDate !== 'N/A') {
          const currentDate = new Date();
          const cancelDate = new Date(cancellationDate);
          
          if ((currentDate > cancelDate) && (membershipType === 'premium')) {
            // User's premium membership has expired
            if (token) {
              await updateUserToFree(token);
            }
            setMembershipType('free');
          } else {
            setMembershipType(membershipType);
          }
        } else {
          setMembershipType(membershipType);
        }
      } catch (error) {
        if (axios.isAxiosError(error) && error.response?.status === 404) {
          // User not found, create the user
          try {
            const userData = await saveUserToMongoDB();
            setMembershipType(userData.membershipType);
          } catch (saveError) {
            setMembershipType('free');
          }
        } else {
          setMembershipType('free');
        }
      }
    }
  }, [session, user, saveUserToMongoDB]);

  const updateUserToFree = async (token: string) => {
    try {
      await axios.put(
        `${API_BASE_URL}/api/users/auth/update-membership`,
        { membershipType: 'free' },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        }
      );
      
      await user?.update({
        unsafeMetadata: { membershipType: 'free', subscriptionStatus: 'free' }
      });
    } catch (error) {
      console.error('Failed to update user to free membership');
    }
  };

  useEffect(() => {
    if (session && user) {
      fetchUserProfile();
    }
  }, [session, user, fetchUserProfile]);

  const isPremium = membershipType === 'premium';

  const handleNavLinkClick = useCallback((to: string) => {
    if (showMenu) {
      toggleMenu();
    }
    navigate(to);
  }, [navigate, showMenu, toggleMenu]);

  const handleSignUpClick = () => {
    onSignUpClick();
    if (showMenu) {
      toggleMenu();
    }
  };

  return (
    <nav className="navbar-container">
      <NavLink to="/" className="nav-title" end onClick={() => handleNavLinkClick('/')}>
        StartUpGeniusAI
      </NavLink>
      <div className={`nav-links ${showMenu ? 'show' : ''}`}>
        {isPremium && (
          <NavLink className="nav-link-genius" to="/forum" onClick={() => handleNavLinkClick('/forum')}>
            Genius
          </NavLink>
        )}
        <NavLink className="nav-link" to="/about-us" onClick={() => handleNavLinkClick('/about-us')}>
          About Us
        </NavLink>
        <SignedIn>
          <NavLink className="nav-link" to="/" onClick={() => handleNavLinkClick('/')}>
          Home
          </NavLink>
          <NavLink className="nav-link" to="/profile" onClick={() => handleNavLinkClick('/profile')}>
            My Account
          </NavLink>
          <UserButton appearance={{
            elements: {
              avatarBox: "user-button-avatar",
              userButtonBox: "user-button-box"
            }
          }} />
        </SignedIn>
        <SignedOut>
          <button className="nav-link" onClick={handleSignUpClick}>Sign Up</button>
        </SignedOut>
      </div>
      <div className={`mobile-menu-icon ${showMenu ? 'active' : ''}`} onClick={toggleMenu}>
        {showMenu ? (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        ) : (
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
          </svg>
        )}
      </div>
    </nav>
  );
}

export default Navbar;