import React, { useState, useEffect, useCallback } from 'react';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { SignedIn, SignedOut, SignInButton, UserButton, useSession } from '@clerk/clerk-react';
import axios from 'axios';
import '../css/ForumNav.css';
import config from '../../config';

const API_BASE_URL = config.API_BASE_URL;

interface UserProfile {
    membershipType: string;
}

const ForumNavbar: React.FC = () => {
    const [showMenu, setShowMenu] = useState(false);
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const { session } = useSession();
    const navigate = useNavigate();
    const location = useLocation();
    const [searchQuery, setSearchQuery] = useState('');

    const calculateMenuHeight = useCallback(() => {
        const navLinks = document.querySelector('.forum-nav-links');
        if (navLinks) {
            const linkCount = navLinks.children.length;
            const linkHeight = 60;
            const padding = 40;
            return linkCount * linkHeight + padding + 40;
        }
        return 0;
    }, []);

    const toggleMenu = useCallback(() => {
        setShowMenu((prevShowMenu) => {
            const newShowMenu = !prevShowMenu;
            document.body.classList.toggle('menu-open', newShowMenu);
            if (newShowMenu && window.innerWidth <= 768) {
                const menuHeight = calculateMenuHeight();
                document.body.style.paddingTop = `${menuHeight}px`;
            } else {
                document.body.style.paddingTop = '0';
            }
            return newShowMenu;
        });
    }, [calculateMenuHeight]);

    const handleNavLinkClick = useCallback((to: string) => {
        if (showMenu) {
            toggleMenu();
        }
        navigate(to);
    }, [navigate, showMenu, toggleMenu]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setShowMenu(false);
                document.body.classList.remove('menu-open');
                document.body.style.paddingTop = '0';
            } else if (showMenu) {
                const menuHeight = calculateMenuHeight();
                document.body.style.paddingTop = `${menuHeight}px`;
            }
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [showMenu, calculateMenuHeight]);

    useEffect(() => {
        // Reset padding and scroll to top when location changes
        document.body.style.paddingTop = '0';
        window.scrollTo(0, 0);
    }, [location]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!session) {
                setIsLoading(false);
                return;
            }

            try {
                const token = await session.getToken();
                const response = await axios.get<UserProfile>(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                setProfile(response.data);
                setIsLoading(false);
            } catch (error) {
                setError('Failed to fetch profile');
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, [session]);

    if (isLoading) {
        return <div>Loading profile...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const isPremium = profile?.membershipType === 'premium';

    if (!isPremium) return null;

    const handleSearch = (e: React.FormEvent) => {
        e.preventDefault();
        if (searchQuery.trim()) {
            navigate(`/forum/search?q=${encodeURIComponent(searchQuery.trim())}`);
        }
    };

    return (
        <nav className="forum-navbar-container">
            <NavLink to="/forum" className="forum-nav-title" end onClick={() => handleNavLinkClick('/forum')}>
                Genius
            </NavLink>
            <div className={`forum-nav-links ${showMenu ? 'show' : ''}`}>
                <form onSubmit={handleSearch} className="forum-search-bar">
                    <input 
                        type="text" 
                        placeholder="Search forums..." 
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                    />
                    <button type="submit">Search</button>
                </form>
                <NavLink className="forum-nav-link" to="/" onClick={() => handleNavLinkClick('/')}>
                    StartUpGeniusAI
                </NavLink>
                <NavLink className="forum-nav-link" to="/forum/inbox" onClick={() => handleNavLinkClick('/forum/inbox')}>
                    Inbox
                </NavLink>
                <NavLink className="forum-nav-link" to="/forum/profile" onClick={() => handleNavLinkClick('/forum/profile')}>
                    My Profile
                </NavLink>
                <SignedIn>
                    <UserButton appearance={{
                        elements: {
                            avatarBox: "user-button-avatar",
                            userButtonBox: "user-button-box"
                        }
                    }} />
                </SignedIn>
                <SignedOut>
                    <SignInButton mode="modal">
                        <button className="forum-nav-link" onClick={toggleMenu}>Sign In</button>
                    </SignInButton>
                </SignedOut>
            </div>
            <div className={`forum-mobile-menu-icon ${showMenu ? 'active' : ''}`} onClick={toggleMenu}>
                {showMenu ? (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                    </svg>
                )}
            </div>
        </nav>
    );
}

export default ForumNavbar;