import React, { useEffect, useState, useMemo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useUser, useSession } from '@clerk/clerk-react';
import axios from 'axios';
import ForumNavbar from './forumComponents/pages/forumNav';
import ForumHome from './forumComponents/pages/ForumHome';
import CreateAPost from './forumComponents/pages/CreateAPost';
import Inbox from './forumComponents/pages/Inbox';
import UserProfile from './forumComponents/pages/UserProfile';
import OtherUserProfile from './forumComponents/pages/OtherUserProfile';
import ViewPost from './forumComponents/pages/ViewPost';
import ForumNavVertical from './forumComponents/pages/forumNavVertical';
import GeniusConnect from './forumComponents/pages/GeniusConnect';
import Legal from './forumComponents/pages/Legal';
import SearchResults from './forumComponents/pages/SearchResults';
import config from './config';
import './Forum.css'; // Import the CSS Module

const API_BASE_URL = config.API_BASE_URL;

interface UserProfile {
    id: string;
    username: string;
    membershipType: string;
}

const Forum: React.FC = () => {
    const { isLoaded: isUserLoaded, user } = useUser();
    const { isLoaded: isSessionLoaded, session } = useSession();
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!isUserLoaded || !isSessionLoaded || !user || !session) {
                return;
            }

            try {
                setIsLoading(true);
                const token = await session.getToken();
                const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                    
                });
                setProfile(response.data);
            } catch (error) {
                setError('An unexpected error occurred');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, [isUserLoaded, isSessionLoaded, user, session]);

    const memoizedProfile = useMemo(() => profile, [profile]);
    const membershipType = useMemo(() => profile?.membershipType, [profile]);

    if (isLoading) {
        return <div>Loading profile...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!user || !memoizedProfile) {
        return <div>No profile data available</div>;
    }

    // Redirect to home if the user is not a premium member
    if (membershipType !== 'premium') {
        return <Navigate to="/" replace />;
    }

    return (
        <div className="forum-container">
            <ForumNavbar />
            <div className="forum-content">
                <ForumNavVertical />
                <div className="forum-main">
                    <Routes>
                        <Route path="/" element={<ForumHome />} />
                        <Route path="/create" element={<CreateAPost />} />
                        <Route path="/inbox" element={<Inbox />} />
                        <Route path="/profile" element={<UserProfile />} />
                        <Route path="/post/:id" element={<ViewPost />} />
                        <Route path="/connections" element={<GeniusConnect />} />
                        <Route path="/legal" element={<Legal />} />
                        <Route path="/user/:username" element={<OtherUserProfile />} />
                        <Route path="/search" element={<SearchResults />} />
                    </Routes>
                </div>
            </div>
        </div>
    );
};

export default Forum;