import React from 'react';
import '../css/loadingComponent.css'; // Optional: Add custom styles

const LoadingPopup: React.FC<{ message?: string }> = ({ message = "Loading..." }) => {
    return (
        <div className="loading-overlay">
            <div className="loading-content">
                <h2>{message}</h2>
                <div className="spinner"></div> {/* Spinner positioned below the text */}
            </div>
        </div>
    );
};

export default LoadingPopup;