import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useSession } from "@clerk/clerk-react";
import axios from 'axios';
import ForumNavbar from './forumNav';
import PostModal from '../objects/PostModal';
import config from '../../config';
import '../css/ForumHome.css';

const categories = [
    "E-commerce", "Law", "Finance", "Healthcare", "Education",
    "Technology", "Manufacturing", "Real Estate", "Marketing",
    "Travel & Hospitality", "Retail", "Entertainment", "Construction",
    "Food & Beverage", "Transport & Logistics", "Energy", "Agriculture", "Fashion"
];

const API_BASE_URL = config.API_BASE_URL;

interface UserProfile {
    membershipType: string;
}

interface Post {
    _id: string;
    title: string;
    categories: string[];
    content: string;
    createdAt: string;
    author: {
        clerkId: string;
        username: string;
    };
    likes: number;
    dislikes: number;
    pinned: boolean;
}

const ForumHome: React.FC = () => {
    const { session } = useSession();
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [posts, setPosts] = useState<Post[]>([]);
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [sortOption, setSortOption] = useState('newest');
    const [selectedCategory, setSelectedCategory] = useState<string | null>(null);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!session) {
                setIsLoading(false);
                return;
            }

            try {
                const token = await session.getToken();
                const response = await axios.get<UserProfile>(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                setProfile(response.data);
            } catch (error) {
                setError('Failed to fetch user profile');
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, [session]);

    useEffect(() => {
        const fetchPosts = async () => {
            if (!session) return;

            try {
                const token = await session.getToken();
                const response = await axios.get<Post[]>(`${API_BASE_URL}/api/forum/posts`, {
                    params: { 
                        page, 
                        limit: 15, 
                        category: selectedCategory,
                        sort: sortOption
                    },
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });


                setPosts(prevPosts => {
                    const newPosts = response.data.filter(newPost => 
                        !prevPosts.some(existingPost => existingPost._id === newPost._id)
                    );
                    
                    // Combine existing and new posts, then sort
                    const allPosts = [...prevPosts, ...newPosts];
                    const sortedPosts = allPosts.sort((a, b) => {
                        // Pinned posts first
                        if (a.pinned && !b.pinned) return -1;
                        if (!a.pinned && b.pinned) return 1;
                        
                        // Then sort by the selected option
                        if (sortOption === 'newest') {
                            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
                        } else if (sortOption === 'oldest') {
                            return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
                        } else if (sortOption === 'popular') {
                            return (b.likes - b.dislikes) - (a.likes - a.dislikes);
                        }
                        return 0;
                    });
                    
                    return sortedPosts;
                });
                setHasMore(response.data.length === 15);
            } catch (error) {
                setError('Failed to fetch posts');
            }
        };

        fetchPosts();
    }, [session, page, sortOption, selectedCategory]);

    const loadMore = () => {
        setPage(prevPage => prevPage + 1);
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!profile) {
        return <div>No profile data available</div>;
    }

    if (profile.membershipType !== 'premium') {
        return (
            <div>
                <h1>You are not a premium member</h1>
                <p>Please upgrade to a premium membership to access this feature</p>
                <Link to="/membership">Upgrade</Link>
            </div>
        );
    }

    return (
        <div className="forum-home">
            <ForumNavbar />
            
            {/* Filter Options */}
            <div className="filter-container">
                <select onChange={(e) => setSortOption(e.target.value)} value={sortOption}>
                    <option value="newest">Newest</option>
                    <option value="oldest">Oldest</option>
                    <option value="popular">Popular</option>
                </select>

                <select onChange={(e) => setSelectedCategory(e.target.value)} value={selectedCategory || ''}>
                    <option value="">All Categories</option>
                    {categories.map(category => (
                        <option key={category} value={category}>{category}</option>
                    ))}
                </select>
            </div>

            <div className="post-container">
                {posts.map(post => (
                    <PostModal
                        key={post._id}
                        _id={post._id}
                        title={post.title}
                        categories={post.categories}
                        content={post.content}
                        author={post.author}
                        likes={post.likes}
                        dislikes={post.dislikes}
                        pinned={post.pinned}
                    />
                ))}
            </div>
            {hasMore && (
                <button onClick={loadMore} className="load-more-button">
                    Load More
                </button>
            )}
        </div>
    );
}

export default ForumHome;