import React, { useState, useEffect } from 'react';
import { useUser, useSession } from "@clerk/clerk-react";
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPopup from '../LoadingPopup';
import config from '../../config';
import '../../css/plan.css';
import LoadingOverlay from '../LoadingOverlay';
// import PreliminarySubmission from './PreliminarySubmission';

const API_BASE_URL = config.API_BASE_URL;

const tierDescriptions = {
    elite: "The Elite tier delivers top-tier features, offering premium product suggestions tailored specifically to your business idea. It focuses on high-end, more expensive options for maximum impact. This may be budgets from $5,000 to $20,000.",
    mid: "The Mid tier strikes the perfect balance between cost and quality, providing a curated selection of products that offer great value for your business needs. This may be budgets from $1,000 to $5,000.",
    low: "The Low tier is ideal for beginners who are just starting out, prioritizing affordable product suggestions to help you launch your business on a budget. This may be budgets from $0 to $1,000."
};

const budgetCategories = {
    low: { min: 0, max: 1000, step: 100 },
    mid: { min: 1000, max: 5000, step: 500 },
    elite: { min: 5000, max: 20000, step: 1000 }
};


interface ProductPlanProps {
    description: string;
    membershipType: string;
    onComplete: (data: object) => void;
}

const ProductPlan: React.FC<ProductPlanProps> = ({ membershipType, onComplete }) => {
    const location = useLocation();
    const { businessIdea } = location.state || { businessIdea: '' };
    const navigate = useNavigate();
    const { isLoaded: isUserLoaded, user } = useUser();
    const { isLoaded: isSessionLoaded, session } = useSession();
    const [, setUserMembership] = useState<string>(membershipType);
    const [error, setError] = useState<string | null>(null);
    const [showLoading, setShowLoading] = useState(false);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [tier, setTier] = useState<'elite' | 'mid' | 'low'>('low');
    const [budget, setBudget] = useState<number>(budgetCategories.low.min);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (isSessionLoaded && session) {
                try {
                    setIsInitialLoading(true);
                    const token = await session.getToken();
                    const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                        headers: { 
                            Authorization: `Bearer ${token}`,
                            'ngrok-skip-browser-warning': 'true'
                        }
                    });
                    const { membershipType } = response.data;
                    setUserMembership(membershipType);
                } catch (error) {
                    setError('Failed to fetch user profile');
                } finally {
                    setIsInitialLoading(false);
                }
            }
        };
    
        fetchUserProfile();
    }, [isSessionLoaded, session]);
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isUserLoaded || !isSessionLoaded || !user || !session) {
            setError('You must be logged in to submit an idea.');
            return;
        }

        if (budget <= 0) {
            setError('Please enter a valid budget.');
            return;
        }

        setError('');
        setShowLoading(true);

        try {
            const token = await session.getToken();

            const response = await axios.post(`${API_BASE_URL}/api/ideas/submit-preliminary`, {
                businessIdea,
                tier,
                budget
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });

            setShowLoading(false);
            onComplete(response.data); // Pass the response data to onComplete

            navigate('/detailed-plan/preliminary', { 
                state: { 
                    businessIdea,
                    productSuggestions: response.data.productSuggestions,
                    tier,
                    budget
                } 
            });
            
        } catch (error) {
            setShowLoading(false);
            setError('An error occurred. Please try again later.');
        }
    };

    const handleTierChange = (selectedTier: 'elite' | 'mid' | 'low') => {
        setTier(selectedTier);
        setBudget(budgetCategories[selectedTier].min);
    };

    const handleBudgetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = Number(e.target.value);
        setBudget(Math.min(Math.max(value, budgetCategories[tier].min), budgetCategories[tier].max));
    };

    return (
        <LoadingOverlay isLoading={isInitialLoading}>
            <div className="Plan">
                {showLoading && <LoadingPopup message="Submitting your idea..." />}
                <div className="container">
                    <h1>Configure Your Plan</h1>
                    <div className="tier-selection">
                        <button className={`tier-button low ${tier === 'low' ? 'selected' : ''}`} onClick={() => handleTierChange('low')}>
                            Low
                        </button>
                        <button className={`tier-button mid ${tier === 'mid' ? 'selected' : ''}`} onClick={() => handleTierChange('mid')}>
                            Mid
                        </button>
                        <button className={`tier-button elite ${tier === 'elite' ? 'selected' : ''}`} onClick={() => handleTierChange('elite')}>
                            Elite
                        </button>
                    </div>
                    <p>{tierDescriptions[tier]}</p> {/* Display the description for the selected tier */}
                        <div>
                            <div className="budget-header-container">
                                <h2>Enter Your Budget</h2>
                            </div>
                            <div className="budget-container">
                                <div className="budget-selection">
                                    <div className="budget-input-wrapper">
                                        <input
                                            type="range"
                                            min={budgetCategories[tier].min}
                                            max={budgetCategories[tier].max}
                                            step={budgetCategories[tier].step}
                                            value={budget}
                                            onChange={handleBudgetChange}
                                            className="budget-slider"
                                        />
                                        <input
                                            type="number"
                                            value={budget}
                                            onChange={handleBudgetChange}
                                            min={budgetCategories[tier].min}
                                            max={budgetCategories[tier].max}
                                            step={budgetCategories[tier].step}
                                            className="budget-input"
                                        />
                                        <button onClick={handleSubmit} className="submit-button">Submit</button>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    {error && <p className="error-message">{error}</p>}
                </div>
            </div>
        </LoadingOverlay>
    );
};


export default ProductPlan;