import React, { useState } from 'react';
import { useSession } from '@clerk/clerk-react';
import axios from 'axios';
import './css/MessageModal.css';
import config from '../../config';
import { FaPaperPlane } from 'react-icons/fa'; // Make sure you have react-icons installed

const API_BASE_URL = config.API_BASE_URL;

interface MessageModalProps {
    recipientUsername: string;
    onClose: () => void;
}

const MessageModal: React.FC<MessageModalProps> = ({ recipientUsername, onClose }) => {
    const [message, setMessage] = useState('');
    const { session } = useSession();

    const handleSendMessage = async () => {
        if (!session || !message.trim()) return;

        try {
            const token = await session.getToken();
            await axios.post(
                `${API_BASE_URL}/api/forum/messages`,
                { recipientUsername, content: message },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': '6969'
                    }
                }
            );
            onClose();
        } catch (error) {
            console.error('Error sending message', );
        }
    };

    return (
        <div className="message-modal-overlay">
            <div className="message-modal">
                <h2>Send Message to {recipientUsername}</h2>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Type your message here..."
                />
                <div className="message-modal-actions">
                    <button onClick={handleSendMessage} className="send-button">
                        <FaPaperPlane /> Send
                    </button>
                    <button onClick={onClose} className="cancel-button">Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default MessageModal;