type StepData = {
    businessOverview?: {
      businessName: string;
      missionStatement: string;
      location: string;
    };
    targetMarket?: {
      targetDemographic: string;
      competitors: Array<{ name: string; url: string }>;
      uniqueSellingPoint: string;
    };
    marketTrends?: {
      economic_factors: {
        inflation: Record<string, number>;
        interest_rates: Record<string, number>;
        unemployment: Record<string, number>;
      };
      industry_growth: {
        past_five_years: string;
        next_five_years_projection: string;
      };
      pricing_trends: Array<{
        year: string;
        averagePrice: number | null;
        futurePrice: number | null;
      }>;
      marketing_and_advertising: Array<{
        name: string;
        value: number;
      }>;
      consumer_behavior: Array<{
        name: string;
        value: number;
      }>;
      source_links: Record<string, string>;
    };
  };
  
export const saveStepData = (step: keyof StepData, data: StepData[keyof StepData]): void => {
  try {
    localStorage.setItem(`businessPlan_${step}`, JSON.stringify(data));
  } catch (error) {
    console.error(`Error saving data to local storage`,);
  }
};

export const getStepData = <T extends keyof StepData>(step: T): StepData[T] | null => {
    try {
        const data = localStorage.getItem(`businessPlan_${step}`);
        return data ? JSON.parse(data) : null;
    } catch (error) {
        console.error(`Error retrieving data from local storage:`);
        return null;
    }
};

export const clearStepData = (step: string): void => {
    try {
        localStorage.removeItem(`businessPlan_${step}`);
    } catch (error) {
        console.error(`Error clearing data from local storage:`);
    }
};