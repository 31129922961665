import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/advertisement.css';

const Advertisement: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  useEffect(() => {
    // Load Google AdSense script dynamically
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7755425611129660";
    script.async = true;
    script.crossOrigin = "anonymous";
    document.head.appendChild(script);

    return () => {
      // Clean up the script when component unmounts
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="advertisement-overlay">
      <div className="advertisement-content">
        <h2>Why are you still on the Free Plan?</h2>
        <p>Upgrade to Premium for more features!</p>
        <div className="upgrade-button-wrapper">
          <Link to="/membership" className="upgrade-button">Upgrade to Premium</Link>
        </div>
        <button className="ad-close-button" onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default Advertisement;