import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/About.css';

const About: React.FC = () => {
  const [activeSection, setActiveSection] = useState('introduction');
  const [showNav, setShowNav] = useState(window.innerWidth >= 1200);

  const sections = [
    { id: 'introduction', title: 'Introduction' },
    { id: 'mission', title: 'Our Mission' },
    { id: 'story', title: 'Our Story' },
    { id: 'how-it-works', title: 'How It Works' },
    { id: 'team', title: 'Meet the Team' },
    { id: 'values', title: 'Our Values' },
    { id: 'contact', title: 'Contact Us' },
    { id: 'cta', title: 'Get Started' },
  ];

  const handleScroll = () => {
    const scrollPosition = window.scrollY;
    const sectionElements = sections.map(section => 
      document.getElementById(section.id)
    );

    sectionElements.forEach((element, index) => {
      if (element) {
        const { offsetTop, offsetHeight } = element;
        if (
          scrollPosition >= offsetTop - 100 &&
          scrollPosition < offsetTop + offsetHeight - 100
        ) {
          setActiveSection(sections[index].id);
        }
      }
    });
  };

  const handleNavClick = (event: React.MouseEvent<HTMLAnchorElement>, sectionId: string) => {
    event.preventDefault();
    const section = document.getElementById(sectionId);
    if (section) {
      const y = section.getBoundingClientRect().top + window.pageYOffset - 100;
      window.scrollTo({top: y, behavior: 'smooth'});
      setActiveSection(sectionId);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    const handleResize = () => setShowNav(window.innerWidth >= 1200);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="about-container">
      <div className="about-main-content">
        <section id="introduction">
          <div className="about-main-header">Welcome to StartUpGeniusAI</div>
          <p>Where your aspirations meet actionable plans! We transform your goals into step-by-step guides and curated product lists, ensuring you're well-equipped to achieve your dreams.</p>
          <p>Meet people with similar dreams and goals! Establish a network of like-minded individuals and collaborate on projects, share resources, and support each other's endeavors.</p>
        </section>

        <section id="mission">
            <h2 className="about-header">Our Mission</h2>
            <p>Our mission is to empower individuals to become the best versions of themselves by providing the resources and guidance needed to turn their ideas into reality. Whether you're starting a new hobby or launching a business, we're dedicated to helping you every step of the way.</p>
        </section>

        <section id="story">
            <h2 className="about-header">Our Story</h2>
            <p>What began as a small idea quickly grew into something much larger. As a computer engineering student in Florida, I started creating everything from the designs to the functionality, aiming to help people realize their dreams. Many people want to break free from the 9-to-5 grind and often have innovative ideas but don't know where to start or what resources they need. That's where we come in. Our platform is designed to guide you through your journey, making it easier to get started and achieve your goals. We are passionate about empowering individuals and supporting them in becoming the best versions of themselves.</p>
        </section>


        <section id="how-it-works">
          <h2 className="about-header">How It Works</h2>
          <p>At StartUpGeniusAI, simply input your goals, and our intelligent system will generate a curated list of products and resources tailored to your needs and budget. From free to premium accounts, we offer various tiers to ensure you get the support you need, whether you're just starting out or looking to take your project to the next level.</p>
        </section>

        <section id="team">
            <h2 className="about-header">Meet the Team</h2>
            <p>Our team is a diverse group of dedicated professionals, each bringing unique skills and passions to support your journey. From tech enthusiasts to future healthcare professionals, we're here to ensure you have the best resources and guidance available.</p>

            <div className="team-member">
                <h3>Michael Perkins</h3>
                <p>As a Computer Engineering major at the University of South Florida, Michael is the driving force behind our innovative technology. His dream is to build a large tech company, and he channels his passion into creating cutting-edge solutions.</p>
                <p>Roles and Interests:</p>
                <ul className="team-member-hobbies">
                    <li>CEO and Founder</li>
                    <li>Working on cars</li>
                    <li>Practicing MMA</li>
                    <li>Hitting the gym</li>
                    <li>Exploring diverse interests</li>
                </ul>
            </div>

            {/* <div className="team-member">
                <h3>Logan Thistle</h3>
                <p>Logan is a Health Sciences major with aspirations of becoming a Physician Assistant. As an investor and shareholder in StartUpGeniusAI, he plays a crucial role in our company's growth. Logan is actively involved in advertising strategies and idea creation, bringing a fresh perspective to our team. His diverse background and interests contribute significantly to our dynamic approach to problem-solving and innovation.</p>
                <p>Roles and Interests:</p>
                <ul className="team-member-hobbies">
                    <li>Investor and Shareholder</li>
                    <li>Advertising and Idea Creation</li>
                    <li>Active investing</li>
                    <li>Practicing MMA</li>
                    <li>Gaming</li>
                </ul>
            </div> */}
        </section>


        <section id="values">
          <h2 className="about-header">Our Values</h2>
          <ul>
            <li>Innovation: Continuously improving our platform to meet your needs.</li>
            <li>Customer-Centricity: Putting our users at the heart of everything we do.</li>
            <li>Integrity: Providing honest and unbiased recommendations.</li>
          </ul>
        </section>

        <section id="contact">
            <h2 className="about-header">Contact Us</h2>
            <p>We'd love to hear from you! Whether you have questions, feedback, or just want to say hello, feel free to reach out to us at <a href="mailto:perkinstech.biz@gmail.com">perkinstech.biz@gmail.com</a>. We look forward to connecting with you!</p>
        </section>

        <section id="cta">
            <h2 className="about-header">Ready to Get Started?</h2>
            <p>Sign up now and start exploring the possibilities with StartUpGeniusAI. Discover how we can help you turn your ideas into reality.</p>
            <div className="cta-grid">
                <div className="button-wrapper get-started-wrapper">
                    <Link to="/" className="get-started-button">Get Started</Link>
                </div>
                <div className="button-wrapper tiktok-wrapper">
                    <a href="https://www.tiktok.com/@startupgeniusai" target="_blank" rel="noopener noreferrer" className="social-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M19.59 6.69a4.83 4.83 0 0 1-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 0 1-5.2 1.74 2.89 2.89 0 0 1 2.31-4.64 2.93 2.93 0 0 1 .88.13V9.4a6.84 6.84 0 0 0-1-.05A6.33 6.33 0 0 0 5 20.1a6.34 6.34 0 0 0 10.86-4.43v-7a8.16 8.16 0 0 0 4.77 1.52v-3.4a4.85 4.85 0 0 1-1-.1z"/></svg>
                        TikTok
                    </a>
                </div>
                <div className="button-wrapper instagram-wrapper">
                    <a href="https://www.instagram.com/startupgeniusai" target="_blank" rel="noopener noreferrer" className="social-link">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg>
                        Instagram
                    </a>
                </div>
            </div>
        </section>
      </div>

      {showNav && (
        <nav className="section-nav">
          <ul>
            {sections.map(section => (
              <li key={section.id}>
                <a 
                  href={`#${section.id}`}
                  className={activeSection === section.id ? 'active' : ''}
                  onClick={(e) => handleNavClick(e, section.id)}
                >
                  {section.title}
                  <span className="nav-bubble"></span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default About;