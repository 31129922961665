// Inbox.tsx for the forum

import React, { useState, useEffect } from 'react';
import { useSession } from '@clerk/clerk-react';
import axios from 'axios';
import InboxMessageModal from '../objects/InboxMessageModal';
import '../css/Inbox.css';
import config from '../../config';

const API_BASE_URL = config.API_BASE_URL;

interface Message {
  _id: string;
  sender: string;
  senderImageUrl: string;
  content: string;
  createdAt: string;
}

const Inbox: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const { session } = useSession();

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = async () => {
    if (!session) return;

    try {
      const token = await session.getToken();
      const response = await axios.get(`${API_BASE_URL}/api/forum/messages`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '6969'
        }
      });
      setMessages(response.data);
    } catch (error) {
      console.error('Error fetching messages' );
    }
  };

  const handleDeleteMessage = async (messageId: string) => {
    if (!session) return;

    try {
      const token = await session.getToken();
      await axios.delete(`${API_BASE_URL}/api/forum/messages/${messageId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '6969'
        }
      });
      setMessages(messages.filter(message => message._id !== messageId));
    } catch (error) {
      console.error('Error deleting message');
    }
  };

  return (
    <div className="inbox-container">
      <h1>Inbox</h1>
      {messages.length === 0 ? (
        <p>No messages in your inbox.</p>
      ) : (
        messages.map(message => (
          <InboxMessageModal
            key={message._id}
            sender={{
              username: message.sender,
              profilePicture: message.senderImageUrl
            }}
            content={message.content}
            timestamp={new Date(message.createdAt).toLocaleString()}
            onDelete={() => handleDeleteMessage(message._id)}
          />
        ))
      )}
    </div>
  );
};

export default Inbox;