import React, { useState, useEffect } from 'react';
import { useUser } from "@clerk/clerk-react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import '../Home.css';

const API_BASE_URL = config.API_BASE_URL;


// Ensure all props types are defined correctly
const validateBusinessIdea = async (idea: string, description: string, session: Session): Promise<boolean> => {
    try {
        const token = await session.getToken();
        const response = await axios.post(
            `${API_BASE_URL}/api/ideas/validate-idea`,
            { idea, description },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'ngrok-skip-browser-warning': 'true'
                }
            }
        );


        const { isLegal } = response.data;
        return isLegal;
    } catch (error) {
        console.error('Error validating business idea');  // Update this line
        return false;
    }
};


// Define types for props
interface Session {
    getToken: () => Promise<string>;
}

interface HomeProps {
    membershipType: string;
    setMembershipType: React.Dispatch<React.SetStateAction<string>>;
    generationsRemaining: number | null;
    setGenerationsRemaining: React.Dispatch<React.SetStateAction<number | null>>;
    premiumGenerationsRemaining: number | null;
    setPremiumGenerationsRemaining: React.Dispatch<React.SetStateAction<number | null>>;
    session: Session | null;
    navigate: ReturnType<typeof useNavigate>;
}

function Home({
    membershipType,
    setMembershipType,
    generationsRemaining,
    setGenerationsRemaining,
    premiumGenerationsRemaining,
    setPremiumGenerationsRemaining,
    session,
    navigate
}: HomeProps) {
    const { user } = useUser();
    const [searchValue, setSearchValue] = useState('');
    const [descriptionValue, setDescriptionValue] = useState('');
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(true);
    const [isDetailedPlan, setIsDetailedPlan] = useState(false);
    const [, setIsProcessingPayment] = useState(false);
    
    // Add this function to handle the payment
    const handlePayment = async () => {
        setIsProcessingPayment(true);
        try {
            if (!session) {
                setError('You must be signed in to use this feature.');
                return;
            }
    
            const response = await axios.post(`${API_BASE_URL}/api/payment/create-checkout-session`, {
                isDetailedPlan: true,
                userId: user?.id
            }, {
                headers: {
                    Authorization: `Bearer ${await session.getToken()}`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            
            window.location.href = response.data.url;
        } catch (error) {
            setError('Payment failed. Please try again.');
            setIsDetailedPlan(false);
        } finally {
            setIsProcessingPayment(false);
        }
    };


    useEffect(() => {
        fetchUserProfile();
    }, [user, session]);

    const fetchUserProfile = async () => {
        if (user && session) {
            try {
                setIsLoading(true);
                const token = await session.getToken();
                const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                
                const profile = response.data;
                const clerkMembershipType = profile.membershipType as string;
                
                // Use Clerk metadata if available, otherwise use the profile from the API
                const finalMembershipType = clerkMembershipType || profile.membershipType;
                setMembershipType(finalMembershipType);
                
                if (finalMembershipType === 'free') {
                    const maxGenerations = 3;
                    const generationsToday = user.publicMetadata.generationsToday as number || profile.generationsToday || 0;
                    const remaining = maxGenerations - generationsToday;
                    setGenerationsRemaining(remaining);
                } else {
                    setGenerationsRemaining(null);
                }

                // Set premium generations remaining
                const premiumGenerations = user.publicMetadata.premiumGenerations as number || profile.premiumGenerations || 0;
                setPremiumGenerationsRemaining(premiumGenerations);

            } catch (error) {
                setError('An error occurred while fetching user profile. Please try refreshing the page.');
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const validateInput = (input: string) => {
        if (input.length > 50) {
            setError("Your input is too long. Please keep it under 50 characters.");
            return false;
        }
        setError('');
        return true;
    };

    const handleGoClick = async () => {
        if (!validateInput(searchValue)) {
            return;
        }
    
        if (!session) {
            setError('You must be signed in to use this feature.');
            return;
        }
    
        setError('');
    
        try {
            const isLegal = await validateBusinessIdea(searchValue, descriptionValue, session as Session);
            if (!isLegal) {
                setError("Your business idea is classified as illegal. Please submit only legal business ideas.");
                return;
            }
    
            // Continue with the rest of the function if the idea is legal
            if (isDetailedPlan) {
                if (membershipType === 'premium') {
                    // Premium users can always generate detailed plans
                } else if (premiumGenerationsRemaining !== null && premiumGenerationsRemaining > 0) {
                    // Free users with available premium generations
                    try {
                        const token = await session.getToken();
                        await axios.post(
                            `${API_BASE_URL}/api/users/auth/decrement-premium-generations`,
                            {},
                            {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'ngrok-skip-browser-warning': 'true'
                                }
                            }
                        );
                        setPremiumGenerationsRemaining(prev => prev !== null ? prev - 1 : null);
                    } catch (error) {
                        setError('Failed to use premium generation. Please try again.');
                        return;
                    }
                } else {
                    setError("You need to purchase premium suggestions or upgrade to premium to generate a detailed plan.");
                    return;
                }
            } else if (membershipType === 'free') {
                if (generationsRemaining !== null && generationsRemaining <= 0) {
                    setError("You have reached your generation limit. Please upgrade to premium for unlimited generations.");
                    return;
                }

                // Call the decrement-daily-generations endpoint for free users
                try {
                    const token = await session.getToken();
                    const decrementResponse = await axios.post(
                        `${API_BASE_URL}/api/users/auth/decrement-daily-generations`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'ngrok-skip-browser-warning': 'true'
                            }
                        }
                    );

                    if (decrementResponse.data.error) {
                        setError(decrementResponse.data.error);
                        setGenerationsRemaining(0);
                        return;
                    }

                    // Update the local state with the new remaining generations
                    setGenerationsRemaining(decrementResponse.data.dailyGenerationsRemaining);
                } catch (error) {
                    setError('Failed to update generation count. Please try again.');
                    return;
                }
            }
    
            try {
                const token = await session.getToken();
                const response = await axios.post(
                    `${API_BASE_URL}/api/ideas/configure-business-type`,
                    { 
                        idea: searchValue,
                        description: descriptionValue,
                        isDetailedPlan: isDetailedPlan
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                            'ngrok-skip-browser-warning': 'true'
                        }
                    }
                );

                console.log('Configure business type response');  // Add this line for debugging

                localStorage.setItem('businessDescription', descriptionValue || '');
    
                const { businessType } = response.data;
                const keywords = searchValue.toLowerCase().split(" ")
                    .filter(word => !["i", "want", "to", "a"].includes(word))
                    .join("-");
                const randomString = Math.random().toString(36).substring(7);
    
                if (isDetailedPlan) {
                    navigate(`/detailed-plan/${keywords}-${randomString}`, { 
                        state: { 
                            businessIdea: searchValue, 
                            description: descriptionValue,
                            membershipType,
                            isPremiumGeneration: membershipType === 'free' && isDetailedPlan
                        } 
                    });
                } else if (businessType === 'product-centric') {
                    navigate(`/plan/${keywords}-${randomString}`, { state: { businessIdea: searchValue, description: descriptionValue, membershipType } });
                } else {
                    navigate(`/service-plan/${keywords}-${randomString}`, { state: { businessIdea: searchValue, description: descriptionValue, membershipType } });
                }
            } catch (error) {
                setError('Failed to configure business type. Please try again.');
            }
        } catch (error) {
            setError('An error occurred while processing your request. Please try again.');
        }
    };


    return (
        <div className="home-page">
            <div className="home-container">
                <div className="home-content">
                    {isLoading ? (
                        <p>Loading...</p>
                    ) : (
                        <>
                            <h1 className="main-text">Make Your Dreams a Reality!</h1>
                            <p className="paragraph-text">Let us kickstart your dreams with our services. Whatever you're interested in, we've got you covered!</p>
                            <div className="search-container">
                                <div className="search-bar-wrapper">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="search-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                                    </svg>
                                    <input
                                        type="text"
                                        placeholder="I want to start a business"
                                        className="search-bar"
                                        value={searchValue}
                                        onChange={(e) => {
                                            setSearchValue(e.target.value);
                                            validateInput(e.target.value);
                                        }}
                                        onKeyPress={(e) => e.key === 'Enter' && handleGoClick()}
                                        aria-label="Search for business ideas"
                                    />
                                </div>
                                <button className="go-button" onClick={handleGoClick}>
                                    Go
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="rocket-icon">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
                                    </svg>
                                </button>
                            </div>
                            {membershipType === 'premium' && (
                                <textarea
                                    placeholder="Describe your business idea in more detail (optional)"
                                    className="description-box"
                                    value={descriptionValue}
                                    onChange={(e) => setDescriptionValue(e.target.value)}
                                    rows={3}
                                    aria-label="Business idea description"
                                />
                            )}
                            {error && <p className="error-message">{error}</p>}
                            {membershipType === 'premium' && (
                                <div className="detailed-plan-container">
                                    <div className="detailed-plan-checkbox-container">
                                        <label className="custom-checkbox">
                                            <input 
                                                type="checkbox" 
                                                id="detailed-plan" 
                                                checked={isDetailedPlan}
                                                onChange={(e) => setIsDetailedPlan(e.target.checked)}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="label-text">
                                                Generate a Detailed Plan
                                            </span>
                                        </label>
                                    </div>
                                </div>
                            )}
                            {membershipType === 'free' && (
                                <div className="detailed-plan-container">
                                    <div className="detailed-plan-checkbox-container">
                                        <label className="custom-checkbox">
                                            <input 
                                                type="checkbox" 
                                                id="detailed-plan" 
                                                checked={isDetailedPlan}
                                                onChange={(e) => {
                                                    setIsDetailedPlan(e.target.checked);
                                                    if (e.target.checked && (premiumGenerationsRemaining === null || premiumGenerationsRemaining <= 0)) {
                                                        alert("You need to purchase premium suggestions or upgrade to premium to use this feature.");
                                                        setIsDetailedPlan(false);
                                                    }
                                                }}
                                            />
                                            <span className="checkmark"></span>
                                            <span className="label-text">
                                                Generate a <span className="glowtext">Premium</span> detailed plan
                                            </span>
                                        </label>
                                    </div>
                                    <button 
                                        className="buy-premium-button"
                                        onClick={handlePayment}
                                    >
                                        Buy Premium Suggestion ($1.99)
                                    </button>
                                </div>
                            )}
                            {membershipType === 'free' && (
                                <div className="generations-remaining-container">
                                    <p className="generations-remaining">Generations Remaining: {generationsRemaining}</p>
                                    <p className="generations-remaining">Premium Generations Remaining: {premiumGenerationsRemaining}</p>
                                </div>
                            )}
                            {membershipType === 'free' && (
                                <div className="upgrade-container">
                                    <p>Want access to more features?</p>
                                    <div className="upgrade-button-wrapper">
                                        <Link to="/membership" className="upgrade-button">Upgrade to Premium</Link>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Home;
