import React, { useState } from 'react';
import '../css/SavePlanModal.css';

interface SavePlanModalProps {
    onSave: (planName: string) => void;
    onClose: () => void;
    businessName: string;
    className?: string;
}

const SavePlanModal: React.FC<SavePlanModalProps> = ({ onSave, onClose, businessName, className }) => {
    const [planName, setPlanName] = useState(businessName);

    const handleSave = () => {
        onSave(planName);
    };

    return (
        <div className={`modal-overlay ${className || ''}`}>
            <div className="modal-content">
                <h2>Save Your Plan</h2>
                <input
                    type="text"
                    value={planName}
                    onChange={(e) => setPlanName(e.target.value)}
                    placeholder="Enter plan name"
                />
                <div className="modal-buttons">
                    <button className="save-plan-button" onClick={handleSave}>Save</button>
                    <button className="cancel-plan-button" onClick={onClose}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

export default SavePlanModal;