import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Footer.css';

const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <img 
          src="/logo/startupgeniusai-high-resolution-logo-transparent.png" 
          alt="StartUpGeniusAI Logo" 
          className="footer-logo"
        />
        <div className="footer-links">
          <Link to="/useragreements" className="footer-link">User Agreements</Link>
          <a href="mailto:perkinstech.biz@gmail.com" className="footer-link">Contact Us</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;