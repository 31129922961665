import React from 'react';

const Legal: React.FC = () => {
    return (
        <div>
            <h1>Legal Policies</h1>
            <p>This page is still under construction</p>
            <p>Our lawyers are working on the content of this page</p>
        </div>
    );
};

export default Legal;