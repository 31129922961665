import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../css/ServicePlan.css';

import BusinessOverview from './steps/BusinessOverview';
import TargetMarket from './steps/TargetMarket';
import MarketTrends from './steps/MarketTrends'; // New component to load market trends

interface ServicePlanProps {
    membershipType: string;
}

const ServicePlan: React.FC<ServicePlanProps> = ({ membershipType }) => {
    const [step, setStep] = useState(1);
    const location = useLocation();
    const { businessIdea } = location.state as { businessIdea: string };
    const [error, setError] = useState<string | null>(null);
    const [businessName, setBusinessName] = useState('');
    const [missionStatement, setMissionStatement] = useState('');
    const [businessLocation, setBusinessLocation] = useState('');
    const navigate = useNavigate();

    const handleNextStep = () => {
        if (step === 2) {
            // After TargetMarket, load market trends and then navigate to summary
            setStep(3);
        } else {
            setStep(prevStep => prevStep + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleMarketTrendsLoaded = () => {
        // Navigate to business summary after market trends are loaded
        navigate('/a/result');
    };

    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="service-plan-container">
            <div className="service-plan">
                {step === 1 && (
                    <BusinessOverview
                        membershipType={membershipType}
                        businessIdea={businessIdea}
                        onNextStep={handleNextStep}
                        setBusinessName={setBusinessName}
                        setMissionStatement={setMissionStatement}
                        setLocation={setBusinessLocation}
                    />
                )}
                {step === 2 && (
                    <TargetMarket
                        membershipType={membershipType}
                        businessIdea={businessIdea}
                        onNextStep={handleNextStep}
                        businessName={businessName}
                        missionStatement={missionStatement}
                        location={businessLocation}
                        setLocation={setBusinessLocation}
                    />
                )}
                {step === 3 && (
                    <MarketTrends
                        membershipType={membershipType}
                        businessIdea={businessIdea}
                        businessName={businessName}
                        onLoadComplete={handleMarketTrendsLoaded}
                        setError={setError}
                    />
                )}
            </div>
        </div>
    );
};

export default ServicePlan;