import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/NotFound.css';

const NotFound = () => {
    useEffect(() => {
        // Add class to body when component mounts
        document.body.classList.add('not-found-page');

        // Cleanup function to remove class when component unmounts
        return () => {
            document.body.classList.remove('not-found-page');
        };
    }, []);

    return (
        <div className="not-found-container">
            <h1 className="not-found-title">Not Found 404</h1>
            <Link to="/" className="home-link">Return Back Home</Link>
        </div>
    );
}

export default NotFound;