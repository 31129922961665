import React from 'react';
import '../css/UserAgreenment.css';
import TermsOfService from './tos.tsx';
import PrivacyPolicy from './PrivacyPolicy';

const ViewAgreenments: React.FC = () => {
  return (
    <div className="user-agreement-page">
      <div className="user-agreement-container">
        <h2>User Agreements</h2>
        <div className="agreements-content">
          <div className="agreement-section">
            <h3>Terms of Service</h3>
            <div className="agreement-text">
              <TermsOfService />
            </div>
          </div>
          <div className="agreement-divider"></div>
          <div className="agreement-section">
            <h3>Privacy Policy</h3>
            <div className="agreement-text">
              <PrivacyPolicy />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewAgreenments;