import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSession } from '@clerk/clerk-react';
import axios from 'axios';
import './css/PostModal.css';
import config from '../../config';

const API_BASE_URL = config.API_BASE_URL;

interface PostModalProps {
  _id: string;
  title: string;
  categories: string[];
  content: string;
  author: {
    username: string;
  };
  likes: number;
  dislikes: number;
  pinned: boolean;
}

const PostModal: React.FC<PostModalProps> = ({ _id, title, categories, content, author, likes, dislikes, pinned: initialPinned }) => {
  const { session } = useSession();
  const navigate = useNavigate();
  const displayCategory = categories.length > 0 ? categories[0] : 'Uncategorized';
  const additionalCategories = categories.length > 1 ? categories.length - 1 : 0;
  const [isPinned, setIsPinned] = useState(initialPinned);

  const handlePinPost = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (!session) return;

    try {
      const token = await session.getToken();
      const action = isPinned ? 'unpin' : 'pin';
      await axios.post(`${API_BASE_URL}/api/forum/posts/${_id}/${action}`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': '6969'
        }
      });
      setIsPinned(!isPinned);
    } catch (error) {
      console.error(`Error`);
    }
  };

  const voteCount = likes - dislikes;
  const voteIcon = voteCount >= 0 ? '👍' : '👎';

  return (
    <div className={`post-modal ${isPinned ? 'pinned' : ''}`} onClick={() => navigate(`/forum/post/${_id}`)}>
      <div className="post-modal-header">
        <h2 className="post-modal-title">{title}</h2>
        <div className="post-modal-categories">
          <span>{displayCategory}</span>
          {additionalCategories > 0 && (
            <span className="additional-categories">+{additionalCategories}</span>
          )}
        </div>
        {session?.user?.username === 'startupgeniusai' && (
          <button onClick={handlePinPost} className="pin-post-button">
            {isPinned ? 'Unpin' : 'Pin'} Post
          </button>
        )}
      </div>
      <hr className="post-modal-divider" />
      <div className="post-modal-content">
        {content.substring(0, 100)}...
      </div>
      <hr className="post-modal-divider" />
      <div className="post-modal-footer">
        <div className="post-modal-author">
          By: <Link to={`/forum/user/${author.username}`} onClick={(e) => e.stopPropagation()}>@{author.username}</Link>
        </div>
        <div className="post-modal-likes">
          {voteIcon} {Math.abs(voteCount)}
        </div>
      </div>
    </div>
  );
};
export default PostModal;
