import React, { useState, useEffect } from 'react';
import { useSession } from "@clerk/clerk-react";
import axios from 'axios';
import '../css/Membership.css';
import PaymentButtons from '../components/PaymentButtons';
import config from '../config';

const API_BASE_URL = config.API_BASE_URL;

const Membership: React.FC = () => {
    const { session } = useSession();
    const [error, setError] = useState<string>('');
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [userMembership, setUserMembership] = useState<string>('free');

    useEffect(() => {
        const fetchMembershipStatus = async () => {
            if (session) {
                try {
                    const token = await session.getToken();
                    const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                        headers: { 
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                            'ngrok-skip-browser-warning': 'true'
                        }
                    });
                    const { membershipType, subscriptionStatus } = response.data;
                    setIsPremium(subscriptionStatus === 'active' && membershipType === 'premium');
                    setUserMembership(membershipType);
                } catch (error) {
                    setError('Failed to fetch membership status.');
                }
            }
        };

        fetchMembershipStatus();
    }, [session]);

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('animate');
                }
            });
        }, { threshold: 0.1 });

        document.querySelectorAll('.feature-row, .pricing-row').forEach(row => {
            observer.observe(row);
        });

        return () => observer.disconnect();
    }, []);

    const features = [
        { name: 'Plan Generations', free: '3 per day', premium: 'Unlimited' },
        { name: 'AI Tools', free: 'Basic', premium: 'Advanced' },
        { name: 'Customer Support', free: 'Standard', premium: 'Priority' },
        { name: 'Ad Experience', free: 'Ad-supported', premium: 'Ad-free' },
        { name: 'Detailed Plan Generations', free: '$1.99 per Generation', premium: '✅' },
        { name: 'Tailored Plans', free: '❌', premium: '✅' },
        { name: 'Genius Forum Access', free: '❌', premium: '✅' },
        { name: 'Investor Portal', free: '❌', premium: '✅' },
        
    ];

    return (
        <div className="membership-page">
            <div className="hero-section">
                <h1>{isPremium ? 'Your Premium Membership' : 'Upgrade to Premium'}</h1>
                <p className="subtitle">
                    {isPremium 
                        ? 'Enjoy your exclusive benefits and take your startup to new heights.' 
                        : 'Unlock the full potential of StartUpGeniusAI and supercharge your startup journey.'}
                </p>
            </div>
            <div className="plan-comparison">
                <div className="plan-header">
                    <div className="plan-name">Feature</div>
                    <div className="plan-name">Free</div>
                    <div className="plan-name">Premium</div>
                </div>
                {features.map((feature, index) => (
                    <div key={index} className="feature-row">
                        <div className="feature-name">{feature.name}</div>
                        <div className="feature-value">{feature.free}</div>
                        <div className="feature-value">{feature.premium}</div>
                    </div>
                ))}
                <div className="pricing-row">
                    <div className="pricing-label">Pricing</div>
                    <div className="pricing-value">
                        <div className="price-option">
                            <span className="price">$0</span>
                            <span>/month</span>
                        </div>
                        {isPremium && <PaymentButtons subType="downgrade" userMembership={userMembership} />}
                    </div>
                    <div className="pricing-value">
                        <div className="price-option">
                            <span className="price">$4.99</span>
                            <span>per month</span>
                            {!isPremium && <PaymentButtons subType="monthly" userMembership={userMembership} />}
                        </div>
                        <div className="price-option featured">
                            <span className="price">$49.99</span>
                            <span>per year</span>
                            <span className="savings">Save 16%</span>
                            {!isPremium && <PaymentButtons subType="yearly" userMembership={userMembership} />}
                        </div>
                    </div>
                </div>
            </div>
            {error && <p className="error-message">{error}</p>}
        </div>
    );
};

export default Membership;