import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useUser, useSession } from '@clerk/clerk-react';
import '../css/profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';
import config from '../config';

const API_BASE_URL = config.API_BASE_URL;

interface UserProfile {
    id: string;
    username: string;
    email: string;
    membershipType: string;
    subscriptionStatus: string;
    savedPlans: {
        name: string;
        createdAt: string;
    }[];
    savedServicePlans: {
        name: string;
        createdAt: string;
    }[];
    savedDetailedPlans: {
        name: string;
        createdAt: string;
    }[];
    cancellationDate?: string;
}

const Profile: React.FC = () => {
    const { isLoaded: isUserLoaded, user } = useUser();
    const { isLoaded: isSessionLoaded, session } = useSession();
    const [profile, setProfile] = useState<UserProfile | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [activeMenu, setActiveMenu] = useState<number | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchProfile = async () => {
            // Ensure user and session are loaded
            if (!isUserLoaded || !isSessionLoaded || !user || !session) {
                setIsLoading(false);
                return;
            }

            try {
                setIsLoading(true);
                const token = await session.getToken();
                const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                setProfile(response.data);

            } catch (error) {
                setError(`An error occurred: ${error instanceof Error ? error.message : 'Unknown error'}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchProfile();
    }, [isUserLoaded, isSessionLoaded, user, session]);

    const memoizedProfile = useMemo(() => profile, [profile]);

    const handleMenuClick = (event: React.MouseEvent<SVGSVGElement>, index: number) => {
        event.stopPropagation();
        setActiveMenu(activeMenu === index ? null : index);
    };

    const handleRename = async (event: React.MouseEvent<HTMLButtonElement>, index: number, planName: string, planType: 'product' | 'service' | 'detailed') => {
        event.stopPropagation();
        const newName = prompt('Enter new name for the plan:', planName);
        if (newName && newName !== planName) {
            try {
                if (!session) throw new Error('No active session');
                const token = await session.getToken();
                const encodedPlanName = encodeURIComponent(planName);
                const response = await axios.put(`${API_BASE_URL}/api/users/auth/rename-plan/${encodedPlanName}`, 
                    { newName, planType },
                    {
                        headers: { 
                            Authorization: `Bearer ${token}`,
                            'ngrok-skip-browser-warning': 'true'
                        }
                    }
                );
                if (response.data.message === 'Plan renamed successfully') {
                    setProfile(prev => ({
                        ...prev!,
                        [planType === 'product' ? 'savedPlans' : planType === 'service' ? 'savedServicePlans' : 'savedDetailedPlans']: 
                            prev?.[planType === 'product' ? 'savedPlans' : planType === 'service' ? 'savedServicePlans' : 'savedDetailedPlans'].map(plan => 
                                plan.name === planName ? { ...plan, name: newName } : plan
                            ) || []
                    }));
                } else {
                    throw new Error(response.data.error || 'Failed to rename plan');
                }
            } catch (error) {
                alert(`Failed to rename plan. ${error instanceof Error ? error.message : 'Please try again.'}`);
            }
        }
    };

    const handleDelete = async (event: React.MouseEvent<HTMLButtonElement>, index: number, planType: 'product' | 'service' | 'detailed') => {
        event.stopPropagation();
        const confirmDelete = window.confirm('Are you sure you want to delete this plan?');
        if (confirmDelete) {
            try {
                if (!session) throw new Error('No active session');
                const token = await session.getToken();
                const planToDelete = memoizedProfile?.[planType === 'product' ? 'savedPlans' : planType === 'service' ? 'savedServicePlans' : 'savedDetailedPlans'][index].name;
                if (!planToDelete) {
                    throw new Error('Plan name not found');
                }
                const encodedPlanName = encodeURIComponent(planToDelete);
                const response = await axios.delete(`${API_BASE_URL}/api/users/auth/delete-plan/${encodedPlanName}`, {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    },
                    data: { planType }
                });
                if (response.data.message === 'Plan deleted successfully') {
                    setProfile(prev => ({
                        ...prev!,
                        [planType === 'product' ? 'savedPlans' : planType === 'service' ? 'savedServicePlans' : 'savedDetailedPlans']: 
                            prev?.[planType === 'product' ? 'savedPlans' : planType === 'service' ? 'savedServicePlans' : 'savedDetailedPlans'].filter((_, i) => i !== index) || []
                    }));
                } else {
                    throw new Error(response.data.error || 'Failed to delete plan');
                }
            } catch (error) {
                alert(`Failed to delete plan. ${error instanceof Error ? error.message : 'Please try again.'}`);
            }
        }
    };

    const handlePlanClick = (plan: { name: string; type: 'product' | 'service' | 'detailed' }) => {
        if (plan.type === 'product') {
            navigate(`/saved-plan/${encodeURIComponent(plan.name)}`);
        } else if (plan.type === 'service') {
            navigate(`/saved-service-plan/${encodeURIComponent(plan.name)}`);
        } else {
            navigate(`/saved-detailed-plan/${encodeURIComponent(plan.name)}`);
        }
    };

    if (isLoading) {
        return <div>Loading profile...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!isUserLoaded || !isSessionLoaded || !user || !memoizedProfile) {
        return <div>No profile data available</div>;
    }

    return (
        <div className="grid-container">
            <div className="main-content">
                <div className="profile-container">
                    <div className="profile-header">
                        <div className="profile-info-container">
                            <img
                                src={user.imageUrl}
                                alt="Profile"
                                className="profile-picture"
                            />
                            <div className="profile-info">
                                <h1 className="profile-name">{user.fullName}</h1>
                                <div className="profile-details">
                                    <p className="profile-membership">Membership: {memoizedProfile.membershipType}</p>
                                    <span className="profile-username">@{memoizedProfile.username}</span>
                                </div>
                            </div>
                        </div>
                        {memoizedProfile.subscriptionStatus === 'canceled' && memoizedProfile.cancellationDate ? (
                            <div className="cancellation-message">
                                <p>Your membership will be downgraded to free on {new Date(memoizedProfile.cancellationDate).toLocaleDateString()}</p>
                            </div>
                        ) : memoizedProfile.subscriptionStatus === 'active' && memoizedProfile.membershipType === 'premium' ? (
                            <div className="upgrade-container">
                                <div className="button-wrapper downgrade-button-wrapper">
                                    <button onClick={() => navigate('/membership')} className="payment-button">
                                        Downgrade to Free
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="upgrade-container">
                                <div className="button-wrapper upgrade-button-wrapper">
                                    <Link to="/membership" className="payment-button">
                                        Upgrade to Premium
                                    </Link>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="additional-content">
                    <div className="saved-plans-grid">
                        <h2>Saved Plans</h2>
                        {memoizedProfile && 
                        ((memoizedProfile.savedPlans?.length > 0) || 
                        (memoizedProfile.savedServicePlans?.length > 0) || 
                        (memoizedProfile.savedDetailedPlans?.length > 0)) ? (
                            <div className="plans-grid">
                                {[
                                    ...(memoizedProfile.savedPlans || []).map(plan => ({ ...plan, type: 'product' as const })),
                                    ...(memoizedProfile.savedServicePlans || []).map(plan => ({ ...plan, type: 'service' as const })),
                                    ...(memoizedProfile.savedDetailedPlans || []).map(plan => ({ ...plan, type: 'detailed' as const }))
                                ].map((plan, index) => (
                                    <div 
                                        key={index} 
                                        className="plan-card" 
                                        onClick={() => handlePlanClick(plan)}
                                    >
                                        <div className="plan-card-header">
                                            <h3>{plan.name}</h3>
                                            <div className="plan-menu">
                                                <FontAwesomeIcon 
                                                    icon={faEllipsisV} 
                                                    onClick={(event: React.MouseEvent<SVGSVGElement>) => handleMenuClick(event, index)}
                                                />
                                                {activeMenu === index && (
                                                    <div className="plan-menu-options">
                                                        <button onClick={(event: React.MouseEvent<HTMLButtonElement>) => handleRename(event, index, plan.name, plan.type)}>Rename</button>
                                                        <button onClick={(event) => handleDelete(event, index, plan.type)}>Delete</button>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <p>Created: {new Date(plan.createdAt).toLocaleDateString()}</p>
                                        <p>Type: {plan.type === 'product' ? 'Product-Oriented Plan' : plan.type === 'service' ? 'Service-Oriented Plan' : 'Detailed Plan'}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p>No Saved Plans</p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );    
}

export default Profile;
