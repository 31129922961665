import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../css/CheckoutSuccess.css';

const CheckoutSuccess: React.FC = () => {
    const navigate = useNavigate()

    const handleGoHome = () => {
        navigate('/');
        window.location.reload(); // Force a page reload to apply new membership
    };

    return (
        <div className="checkout-success-container">
            <div className="checkout-success-content">
                <h1>Checkout Successful!</h1>
                <p>Thank you for your purchase!</p>
                <button onClick={handleGoHome} className="go-home-button">Go to Home</button>
            </div>
        </div>
    );
};

export default CheckoutSuccess;