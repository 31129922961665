// ViewPost.tsx

import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { useSession } from '@clerk/clerk-react';
import axios from 'axios';
import config from '../../config';
import '../css/ViewPost.css';
import FetchUserProfile from '../FetchUserProfile';
import Comment from '../objects/Comment';

const API_BASE_URL = config.API_BASE_URL;

interface Post {
    _id: string;
    title: string;
    content: string;
    categories: string[];
    author: string; // This should be just the username
    likes: number;
    dislikes: number;
    createdAt: string;
}

interface CommentType {
    _id: string;
    author: string;
    content: string;
    createdAt: string;
    likes: number;
    dislikes: number;
    parentId: string | null;
    replies: CommentType[];
    pinned: boolean
}

const TrashIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-trash-2"
        {...props}
    >
        <path d="M3 6h18" />
        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        <path d="M10 11v6" />
        <path d="M14 11v6" />
    </svg>
);

const ViewPost: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const { session } = useSession();
    const [post, setPost] = useState<Post | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [userVote, setUserVote] = useState<'like' | 'dislike' | null>(null);
    const [comments, setComments] = useState<CommentType[]>([]);
    const [newCommentContent, setNewCommentContent] = useState('');
    const [showComments, setShowComments] = useState(false);

    useEffect(() => {
        const fetchPost = async () => {
            if (!session) {
                return;
            }
            const token = await session.getToken();
            try {
                const response = await axios.get(`${API_BASE_URL}/api/forum/posts/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                if (response.data && typeof response.data === 'object' && response.data.content) {
                    setPost(response.data);
                } else {
                    throw new Error('Invalid post data received');
                }
            } catch (error) {
                setError('Failed to fetch post');
            } finally {
                setIsLoading(false);
            }
        };

        const fetchComments = async () => {
            if (!session) {
                return;
            }
            const token = await session.getToken();
            try {
                const response = await axios.get(`${API_BASE_URL}/api/forum/posts/${id}/comments`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                setComments(response.data);
            } catch (error) {
                console.error('Error fetching comments');
            }
        };

        fetchPost();
        if (showComments) {
            fetchComments();
        }
    }, [id, showComments]);

    const handleVote = async (voteType: 'like' | 'dislike') => {
        if (!post || !session) return;
        try {
            const token = await session.getToken();
            const response = await axios.post(
                `${API_BASE_URL}/api/forum/posts/${id}/${voteType}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'ngrok-skip-browser-warning': 'true'
                    }
                }
            );
            setPost(prevPost => ({
                ...prevPost!,
                likes: response.data.likes,
                dislikes: response.data.dislikes
            }));
            setUserVote(response.data.userVote);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                console.error('Error voting');
            }
            // Add user-friendly error handling here
        }
    };

    const handleDeletePost = async () => {
        if (!post) return;

        try {
            if (!session) {
                return;
            }
            const token = await session.getToken();
            await axios.delete(`${API_BASE_URL}/api/forum/posts/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'ngrok-skip-browser-warning': 'true'
                }
            });
            navigate('/forum');
        } catch (error) {
            console.error('Error deleting post');
        }
    };

    const handleAddComment = async () => {
        if (!session || !newCommentContent.trim()) {
            console.error('Session not found or comment content is empty');
            return;
        }
    
        try {
            const token = await session.getToken();
            const response = await axios.post(
                `${API_BASE_URL}/api/forum/posts/${id}/comments`,
                { content: newCommentContent },
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                        'ngrok-skip-browser-warning': 'true'
                    }
                }
            );
    
            setComments(prevComments => [...prevComments, response.data]);
            setNewCommentContent('');
        } catch (error) {
            console.error('Error adding comment');
            // Add user-friendly error handling here
        }
    };

    const addReplyToComment = (comments: CommentType[], parentId: string, newReply: CommentType): CommentType[] => {
        const updatedComments = comments.map(comment => {
            if (comment._id === parentId) {
                return {
                    ...comment,
                    replies: [...(comment.replies || []), newReply]
                };
            } else if (comment.replies && comment.replies.length > 0) {
                return {
                    ...comment,
                    replies: addReplyToComment(comment.replies, parentId, newReply)
                };
            }
            return comment;
        });
        return updatedComments;
    };

    const handleAddReply = async (parentId: string, replyContent: string) => {
        if (!session) {
            return;
        }

        try {
            const token = await session.getToken();
            const response = await axios.post(`${API_BASE_URL}/api/forum/posts/${id}/comments`, {
                content: replyContent,
                parentId: parentId
            }, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': '6969'
                }
            });

            setComments(prevComments => {
                const updatedComments = addReplyToComment(prevComments, parentId, response.data);
                return updatedComments;
            });
        } catch (error) {
            console.error('Error adding reply');
        }
    };

    const handleDeleteComment = (commentId: string) => {
        setComments(prevComments => removeCommentAndReplies(prevComments, commentId));
    };

    const removeCommentAndReplies = (comments: CommentType[], commentId: string): CommentType[] => {
        return comments.filter(comment => {
            if (comment._id === commentId) {
                return false;
            }
            if (Array.isArray(comment.replies) && comment.replies.length > 0) {
                comment.replies = removeCommentAndReplies(comment.replies, commentId);
            }
            return true;
        });
    };

    if (isLoading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (!post) return <div>Post not found</div>;

    return (
        <FetchUserProfile>
            {(profile, isProfileLoading, profileError) => {
                if (isProfileLoading) return <div>Loading user profile...</div>;
                if (profileError) return <div>Error loading user profile: {profileError}</div>;

                const isAuthor = profile?.username === post.author;

                return (
                    <div className="view-post-container">
                        <div className="post-header">
                            <span className="author">
                                <Link to={`/forum/users/${post.author}`}>@{post.author}</Link> says
                            </span>
                            {isAuthor && (
                                <button onClick={handleDeletePost} className="delete-button">
                                    <TrashIcon className="delete-icon" />
                                    Delete
                                </button>
                            )}
                        </div>
                        <hr className="separator" />
                        <div className="post-title-categories">
                            <h1 className="post-title">{post.title}</h1>
                            {post.categories && post.categories.length > 0 && (
                                <div className="post-category-container">
                                    {post.categories.map(category => (
                                        <span key={category} className="post-category-badge">
                                            {category}
                                        </span>
                                    ))}
                                </div>
                            )}
                        </div>
                        <hr className="separator" />
                        <div className="post-content">
                            {post.content.split('\n').map((paragraph, index) => (
                                <p key={index}>{paragraph}</p>
                            ))}
                        </div>
                        <hr className="separator" />
                        <div className="post-footer">
                            <div className="vote-section">
                                <span className="vote-count">{post.likes - post.dislikes}</span>
                                <button 
                                    onClick={() => handleVote('like')} 
                                    className={`vote-button ${userVote === 'like' ? 'active' : ''}`}
                                >
                                    👍
                                </button>
                                <button 
                                    onClick={() => handleVote('dislike')} 
                                    className={`vote-button ${userVote === 'dislike' ? 'active' : ''}`}
                                >
                                    👎
                                </button>
                            </div>
                            <button onClick={() => setShowComments(!showComments)} className="comment-button">
                                {showComments ? 'Hide Comments' : 'Show Comments'} 💬
                            </button>
                        </div>
                        {showComments && (
                            <div className="comments-section">
                                <h2>Comments</h2>
                                <div className="add-comment">
                                    <textarea
                                        value={newCommentContent}
                                        onChange={(e) => setNewCommentContent(e.target.value)}
                                        placeholder="Write your comment..."
                                    />
                                    <button onClick={handleAddComment} className="submit-comment-button">
                                        <span>Submit</span>
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="submit-comment-icon">
                                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                                        </svg>
                                    </button>
                                </div>
                                <div className="comments-list">
                                    {comments.filter(comment => !comment.parentId).map((comment) => (
                                        <Comment
                                            key={comment._id}
                                            comment={comment}
                                            onAddReply={handleAddReply}
                                            onDeleteComment={handleDeleteComment}
                                            postId={id!}
                                            currentUser={profile?.username || null}
                                        />
                                    ))}
                                </div>
                            </div>
                        )}
                    </div>
                );
            }}
        </FetchUserProfile>
    );
};


export default ViewPost;