import React, { useEffect } from 'react';
import { useSession } from '@clerk/clerk-react';
import axios from 'axios';
import config from '../../config';
import { getStepData } from '../../utils/localStorageUtils';

const API_BASE_URL = config.API_BASE_URL;

interface LoadMarketTrendsProps {
    membershipType: string;
    businessIdea: string;
    businessName: string;
    onLoadComplete: () => void;
    setError: (error: string | null) => void;
}

const MarketTrends: React.FC<LoadMarketTrendsProps> = ({ businessIdea, businessName, onLoadComplete, setError }) => {
    const { session } = useSession();

    useEffect(() => {
        const fetchMarketTrends = async () => {
            if (!session) {
                setError('No active session');
                return;
            }

            try {
                const businessOverview = getStepData('businessOverview');
                const targetMarket = getStepData('targetMarket');
                const location = businessOverview?.location || null;
                const targetDemographic = targetMarket?.targetDemographic || null;

                const token = await session.getToken();
                const response = await axios.post(
                    `${API_BASE_URL}/api/ideas/identify-market-trends`,
                    { businessIdea, businessName, location, targetDemographic },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'ngrok-skip-browser-warning': 'true'
                        }
                    }
                );

                // Store the market trends data in localStorage
                localStorage.setItem('marketTrends', JSON.stringify(response.data));

                // Call the onLoadComplete callback
                onLoadComplete();
            } catch (error) {
                setError('Failed to fetch market trends. Please try again.');
            }
        };

        fetchMarketTrends();
    }, [session, businessIdea, businessName, onLoadComplete, setError]);

    return <div>Loading market trends...</div>;
};

export default MarketTrends;