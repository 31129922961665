import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ServicePlan from './detailedPlanSteps/ServicePlan';
import ProductPlan from './detailedPlanSteps/ProductPlan';
import PreliminarySubmissionA from './detailedPlanSteps/PreliminarySubmission';
import { DetailedPlanResult } from './detailedPlanSteps/DetailedPlanResult';

interface DetailedPlanProps {
  businessIdea: string;
  description: string;
  membershipType: string;
}

interface ProductPlanData {
  productSuggestions: string[];
  businessIdea: string;
  tier: string;
  budget: number;
}

const DetailedPlan: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState<'service' | 'product' | 'preliminary' | 'result'>('service');
  const [productPlanData, setProductPlanData] = useState<ProductPlanData | null>(null);

  // Safely access location.state, providing default values if it's null
  const { businessIdea = '', description = '', membershipType = '' } = (location.state as DetailedPlanProps) || {};

  // If essential data is missing, redirect to home page
  useEffect(() => {
    if (!businessIdea || !membershipType) {
      navigate('/');
    }
  }, [businessIdea, membershipType, navigate]);

  const handleServicePlanComplete = () => {
    setCurrentStep('product');
  };

  const handleProductPlanComplete = (data: ProductPlanData) => {
    setProductPlanData(data);
    setCurrentStep('preliminary');
  };

  if (!businessIdea || !membershipType) {
    return null;  // or a loading indicator
  }

  switch (currentStep) {
    case 'service':
      return (
        <ServicePlan
          businessIdea={businessIdea}
          description={description}
          membershipType={membershipType}
          onComplete={handleServicePlanComplete}
        />
      );
    case 'product':
      return (
        <ProductPlan
        description={description}
        membershipType={membershipType}
        onComplete={(data: object) => handleProductPlanComplete(data as ProductPlanData)}
        />
      );
    case 'preliminary':
      return productPlanData ? (
        <PreliminarySubmissionA
        />
      ) : null;
    case 'result':
      return (
        <DetailedPlanResult/>
      );
    default:
      return null;
  }
};

export default DetailedPlan;
