import React, { useState } from 'react';
import axios from 'axios';
import config from '../../config';
import { useSession } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import './css/Comment.css';

const API_BASE_URL = config.API_BASE_URL;

interface CommentProps {
    comment: {
        _id: string;
        author: string | null;
        content: string;
        createdAt: string;
        likes: number;
        dislikes: number;
        parentId: string | null;
        replies: CommentProps['comment'][];
        pinned: boolean;
    };
    postId: string;
    onAddReply: (parentId: string, replyContent: string) => void;
    onDeleteComment: (commentId: string) => void;
    currentUser: string | null;
}

const TrashIcon: React.FC<React.SVGProps<SVGSVGElement>> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-trash-2"
        {...props}
    >
        <path d="M3 6h18" />
        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        <path d="M10 11v6" />
        <path d="M14 11v6" />
    </svg>
);

const Comment: React.FC<CommentProps> = ({ comment, postId, onAddReply, onDeleteComment, currentUser }) => {
    const [replyContent, setReplyContent] = useState('');
    const [showReplyForm, setShowReplyForm] = useState(false);
    const { session } = useSession();
    // const [likes, setLikes] = useState(comment.likes || 0);
    // const [dislikes, setDislikes] = useState(comment.dislikes || 0);
    const [isPinned, setIsPinned] = useState(comment.pinned);

    const handleAddReply = () => {
        if (!replyContent.trim()) {
            return;
        }

        onAddReply(comment._id, replyContent);
        setReplyContent('');
        setShowReplyForm(false);
    };

    const handleDeleteComment = async () => {
        if (!session) {
            return;
        }

        try {
            const token = await session.getToken();
            await axios.delete(`${API_BASE_URL}/api/forum/posts/${postId}/comments/${comment._id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'ngrok-skip-browser-warning': '6969',
                },
            });
            onDeleteComment(comment._id);
        } catch (error) {
            console.error('Error deleting comment');
        }
    };

    // Commented out liking functionality
    /*
    const handleLikeComment = async () => {
        // ... (previous implementation)
    };

    const handleDislikeComment = async () => {
        // ... (previous implementation)
    };
    */

    const handlePinComment = async (e: React.MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (!session) {
            console.error('User is not logged in');
            return;
        }
        try {
            const token = await session.getToken();
            const action = isPinned ? 'unpin' : 'pin';
            await axios.post(
                `${API_BASE_URL}/api/forum/posts/${postId}/comments/${comment._id}/${action}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                }
            );
            setIsPinned(!isPinned);
        } catch (error) {
            console.error('Error pinning/unpinning comment');
        }
    };

    return (
        <div className={`comment ${isPinned ? 'pinned' : ''}`}>
            <div className="comment-header">
            <Link to={`/forum/user/${comment.author || 'unknown'}`} className="comment-author">
                @{comment.author || 'Unknown User'}
            </Link>
                {currentUser === comment.author && (
                    <button onClick={handleDeleteComment} className="delete-comment-button">
                        <TrashIcon />
                    </button>
                )}
                {currentUser === 'startupgeniusai' && (
                    <button onClick={handlePinComment} className="pin-comment-button">
                        {isPinned ? 'Unpin' : 'Pin'} Comment
                    </button>
                )}
            </div>
            <div className="comment-content">{comment.content}</div>
            <div className="comment-actions">
                {/* Commented out like/dislike UI
                <div className="left-actions">
                    <span className="like-dislike-difference">{(likes || 0) - (dislikes || 0)}</span>
                    <button onClick={handleLikeComment} className="like-button">
                        👍
                    </button>
                    <button onClick={handleDislikeComment} className="dislike-button">
                        👎
                    </button>
                </div>
                */}
                <button onClick={() => setShowReplyForm(!showReplyForm)} className="reply-button">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                    </svg>
                </button>
            </div>
            {showReplyForm && (
                <div className="reply-form">
                    <textarea
                        value={replyContent}
                        onChange={(e) => setReplyContent(e.target.value)}
                        placeholder="Write your reply..."
                    />
                    <button onClick={handleAddReply} className="submit-comment-button">
                        <span>Submit</span>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="submit-comment-icon">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
                        </svg>
                    </button>
                </div>
            )}
            {comment.replies && comment.replies.length > 0 && (
                <div className="comment-replies">
                    {comment.replies.map((reply) => (
                        <Comment 
                            key={reply._id} 
                            comment={reply} 
                            postId={postId} 
                            onAddReply={onAddReply} 
                            onDeleteComment={onDeleteComment}
                            currentUser={currentUser}
                        />
                    ))}
                </div>
            )}
        </div>
    );
};

export default Comment;