import React from 'react';
import '../css/UserAgreenment.css';

interface UserAgreementProps {
  onSubmit: (tosAccepted: boolean, privacyAccepted: boolean) => void;
  onCancel: () => void;
}

const UserAgreement: React.FC<UserAgreementProps> = ({ onSubmit, onCancel }) => {
  const handleSubmit = () => {
    onSubmit(true, true); // Submit as accepted by default
  };

  return (
    <>
      <div className="overlay" onClick={onCancel}></div>
      <div className="user-agreement-container">
        <h2>User Agreements</h2>
        <h3>By creating an account you are agreeing to our<br/><a href="/useragreements">Terms of Service</a> and <a href="/useragreements">Privacy Policy</a>.
            <br/>
            <div className="disclaimer">
                You also understand that results received from this site is NOT financial advice.<br/>
                Some results may be inaccurate or misleading or contain copyrighted material.
            </div>
        </h3>
        <div className="agreement-buttons">
          <button
            onClick={handleSubmit}
            className="submit-agreement-button"
          >
            Continue
          </button>
          <button onClick={onCancel} className="cancel-agreement-button">Cancel</button>
        </div>
      </div>
    </>
  );
};

export default UserAgreement;