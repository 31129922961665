import React, { useState } from 'react';
import '../../css/ServicePlan.css';

import BusinessOverview from '../steps/BusinessOverview';
import TargetMarket from '../steps/TargetMarket';
import MarketTrends from './MarketTrends'; // New component to load market trends
import ProductPlan from './ProductPlan';

interface ServicePlanProps {
    businessIdea: string;
    description: string;
    membershipType: string;
    onComplete: () => void;
}

const ServicePlan: React.FC<ServicePlanProps> = ({ businessIdea, description, membershipType, onComplete }) => {
    const [step, setStep] = useState(1);
    const [error, setError] = useState<string | null>(null);
    const [businessName, setBusinessName] = useState('');
    const [missionStatement, setMissionStatement] = useState('');
    const [businessLocation, setBusinessLocation] = useState('');

    const handleNextStep = () => {
        if (step < 3) {
            setStep(prevStep => prevStep + 1);
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    };

    const handleMarketTrendsLoaded = () => {
        // This function is called when MarketTrends completes
        onComplete(); // Call the onComplete prop to signal ServicePlan completion
    };

    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="service-plan-container">
            <div className="service-plan">
                {step === 1 && (
                    <BusinessOverview
                        membershipType={membershipType}
                        businessIdea={businessIdea}
                        onNextStep={handleNextStep}
                        setBusinessName={setBusinessName}
                        setMissionStatement={setMissionStatement}
                        setLocation={setBusinessLocation}
                    />
                )}
                {step === 2 && (
                    <TargetMarket
                        membershipType={membershipType}
                        businessIdea={businessIdea}
                        onNextStep={handleNextStep}
                        businessName={businessName}
                        setLocation={setBusinessLocation}
                        missionStatement={missionStatement}
                        location={businessLocation}
                    />
                )}
                {step === 3 && (
                    <MarketTrends
                        membershipType={membershipType}
                        businessIdea={businessIdea}
                        businessName={businessName}
                        onLoadComplete={handleMarketTrendsLoaded}
                        setError={setError}
                    />
                )}
                {step === 4 && (
                    <ProductPlan
                        description={description}
                        membershipType={membershipType}
                        onComplete={onComplete}
                    />
                )}
            </div>
        </div>
    );
};

export default ServicePlan;