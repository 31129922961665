import { useEffect, useState } from 'react';
import { useUser, useSession, useClerk } from "@clerk/clerk-react";
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom'; // Import useLocation
import axios from 'axios';
import Navbar from './components/navbar';
import Home from './components/Home';
import Plan from './components/plan';
import Profile from './pages/Profile';
import Results from './components/results';
import SavedPlan from './components/SavedPlan';
import LearnMore from './components/LearnMore';
import Membership from './pages/Membership';
import CheckoutSuccess from './components/CheckoutSuccess';
import NotFound from './components/NotFound';
import PreliminarySubmission from './components/PreliminarySubmission';
import PreliminarySubmissionA from './components/detailedPlanSteps/PreliminarySubmission';
import Forum from './Forum';
import BodyClassManager from './components/BodyClassManager';
import About from './pages/About';
import ViewAgreenments from './components/ViewAgreenments';
import Footer from './components/Footer';
import MarketingAdvisor from './pages/MarketingAdvisor';
import LoadingOverlay from './components/LoadingOverlay';
import UserAgreement from './components/UserAgreenment';
import AnalyzeMarket from './pages/AnalyzeMarket';
import SavedServicePlan from './components/SavedServicePlan';
import Branding from './pages/Branding';
import ServicePlan from './components/ServicePlan';
import BusinessPlanSummary from './components/steps/BusinessPlanSummary';
import { DetailedPlanResult } from './components/detailedPlanSteps/DetailedPlanResult';
import SavedDetailedPlan from './components/SavedDetailedPlan';
import { useScrollToTop } from './utils/ScrollToTop';
import './App.css';
import './Home.css';
import './Forum.css';
import DetailedPlan from './components/DetailedPlan';
import FeatureCard from './components/FeatureCard';
import './css/FeatureCard.css';

import config from './config';

const API_BASE_URL = config.API_BASE_URL;

// Define types for props
interface Session {
    getToken: () => Promise<string>;
}

const isSession = (session: unknown): session is Session => {
    return session !== null && typeof session === 'object' && 'getToken' in session && typeof (session as { getToken: unknown }).getToken === 'function';
};

export default function App() {
    const { isSignedIn, user } = useUser();
    const { session } = useSession();
    const { openSignUp } = useClerk();
    const [membershipType, setMembershipType] = useState("free");
    const [isLoading, setIsLoading] = useState(true);
    const [generationsRemaining, setGenerationsRemaining] = useState<number | null>(null);
    const [premiumGenerationsRemaining, setPremiumGenerationsRemaining] = useState<number | null>(null);
    const [error, setError] = useState("");
    const [showUserAgreement, setShowUserAgreement] = useState(false);
    const navigate = useNavigate();
    const location = useLocation(); // Get the current location

    const isForumPage = location.pathname.startsWith('/forum');

    useEffect(() => {
        const delayFetchUserProfile = () => {
            setTimeout(() => {
                fetchUserProfile();
            }, 1200);
        };

        delayFetchUserProfile();
    }, [isSignedIn, user]);

    useScrollToTop();

    const fetchUserProfile = async (retryCount = 1) => {
        if (isSignedIn && user && session) {
            try {
                setIsLoading(true);
                const token = await session.getToken();
                const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                
                const profile = response.data;
                const clerkMembershipType = user.publicMetadata.membershipType as string;
                
                // Use Clerk metadata if available, otherwise use the profile from the API
                const finalMembershipType = clerkMembershipType || profile.membershipType;
                setMembershipType(finalMembershipType);
                
                if (finalMembershipType === 'free') {
                    const maxGenerations = 3;
                    const generationsToday = user.publicMetadata.generationsToday as number || profile.generationsToday || 0;
                    const remaining = maxGenerations - generationsToday;
                    setGenerationsRemaining(remaining);
                } else {
                    setGenerationsRemaining(null);
                }

                // Set premium generations remaining
                const premiumGenerations = user.publicMetadata.premiumGenerations as number || profile.premiumGenerations || 0;
                setPremiumGenerationsRemaining(premiumGenerations);

                setError("");
            } catch (error) {
                setError('An error occurred while fetching user profile. Try refreshing the page.');
                if (retryCount > 0) {
                    setTimeout(() => fetchUserProfile(retryCount - 1), 1000);
                } else {
                    window.location.reload();
                }
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const handleUserAgreementSubmit = async (tosAccepted: boolean, privacyAccepted: boolean) => {
        setShowUserAgreement(false);
        if (tosAccepted && privacyAccepted) {
            try {
                await openSignUp();
            } catch (err) {
                console.error('Error during sign up');
            }
        }
    };

    const handleUserAgreementCancel = () => {
        setShowUserAgreement(false);
    };

    const freeUserCards = [
        {
            title: "Generate Product Recommendations",
            description: "Generate product suggestions, tailored to your business idea",
            imageSrc: `/images/free-list-img.png`,
            ctaText: "Get Started",
            onClick: () => navigate('/business-planning')
        },
        {
            title: "Basic Branding Tools",
            description: "Create your business identity with a logo, business name, and mission statement using AI-powered tools.",
            imageSrc: "/images/branding-img.png",
            ctaText: "Create Now",
            onClick: () => navigate('/branding')
        },
        {
            title: "Market Identification",
            description: "Identify your key competitors and best target market to refine your business approach.",
            imageSrc: "/images/free-market-img.png",
            ctaText: "Analyze Market",
            onClick: () => navigate('/market-analysis')
        }
    ];

    const premiumUserCards = [
        {
            title: "Comprehensive Business Plan",
            description: "Get a full AI-generated business plan with advanced branding tools, financial projections, and market strategies.",
            imageSrc: `/images/premium-bp-img.png`,
            ctaText: "Generate Plan",
            onClick: () => navigate('/business-planning')
        },
        {
            title: "Advanced Branding Tools",
            description: "Tailor your business with interactive AI branding tools.",
            imageSrc: "/images/branding-img.png",
            ctaText: "Get Creating",
            onClick: () => navigate('/branding')
        },
        {
            title: "AI Marketing Campaign Advisor",
            description: "Create and optimize marketing campaigns with AI insights on audience targeting, messaging, and platform recommendations.",
            imageSrc: "/images/premium-ai-team.png",
            ctaText: "Start Campaign",
            onClick: () => navigate('/marketing-advisor')
        }
    ];

    return (
        <LoadingOverlay isLoading={isLoading}>
            <BodyClassManager />
            {!isForumPage && <Navbar onSignUpClick={() => setShowUserAgreement(true)} />}
            <main className="main-content">
                {error && <p className="error-message">{error}</p>}
                <Routes>
                    {/* New root route for feature cards */}
                    <Route path="/" element={
                        isSignedIn ? (
                            <div className="feature-cards-container">
                                {(membershipType === 'free' ? freeUserCards : premiumUserCards).map((card, index) => (
                                    <FeatureCard
                                        key={index}
                                        title={card.title}
                                        description={card.description}
                                        ctaText={card.ctaText}
                                        onClick={card.onClick}
                                        imageSrc={card.imageSrc}
                                    />
                                ))}
                            </div>
                        ) : (
                            <LearnMore onSignUpClick={() => setShowUserAgreement(true)} />
                        )
                    } />

                    {/* Move Home component to /business-planning */}
                    <Route path="/business-planning" element={
                        <Home 
                            membershipType={membershipType} 
                            setMembershipType={setMembershipType}
                            generationsRemaining={generationsRemaining}
                            setGenerationsRemaining={setGenerationsRemaining}
                            premiumGenerationsRemaining={premiumGenerationsRemaining}
                            setPremiumGenerationsRemaining={setPremiumGenerationsRemaining}
                            session={isSession(session) ? session : null}
                            navigate={navigate}
                        />
                    } />

                    <Route path="/profile" element={<Profile />} />
                    <Route path="/checkout-success" element={<CheckoutSuccess />} />
                    <Route path="/plan/:searchText" element={<Plan />} />
                    <Route path="/results" element={<Results />} />
                    <Route path="/a/result" element={<BusinessPlanSummary />} />
                    <Route path="/saved-plan/:planName" element={<SavedPlan />} />
                    <Route path="/service-plan/:planName" element={<ServicePlan membershipType={membershipType} />} />
                    <Route path="/saved-service-plan/:planName" element={<SavedServicePlan />} />
                    <Route path="/membership" element={<Membership />} />
                    <Route path="*" element={<NotFound />} />
                    <Route path="/preliminary-submission" element={<PreliminarySubmission />} />
                    <Route path="/forum/*" element={<Forum />} />
                    <Route path="/about-us" element={<About />} />
                    <Route path="/branding" element={<Branding />} />
                    <Route path="/useragreements" element={<ViewAgreenments />} />
                    <Route path="/detailed-plan/:planName" element={<DetailedPlan />} />
                    <Route path="/detailed-plan/preliminary" element={<PreliminarySubmissionA />} />
                    <Route path="/market-analysis" element={<AnalyzeMarket />} />
                    <Route path="/detailed-plan/result" element={<DetailedPlanResult />} />
                    <Route path="/marketing-advisor" element={<MarketingAdvisor />} />
                    <Route path="/saved-detailed-plan/:planName" element={<SavedDetailedPlan />} />
                </Routes>
            </main>
            
            {showUserAgreement && (
                <UserAgreement
                    onSubmit={handleUserAgreementSubmit}
                    onCancel={handleUserAgreementCancel}
                />
            )}
            <Footer />
        </LoadingOverlay>
    );
}
