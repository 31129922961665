import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { useSession } from "@clerk/clerk-react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { motion } from 'framer-motion';
import '../css/results.css';
import '../css/ServicePlan.css';
import '../css/BusinessPlanSummary.css';
import '../css/steps/Market.css';
import config from '../config';
import LoadingPopup from './LoadingPopup';

const API_BASE_URL = config.API_BASE_URL;

interface MarketTrendsData {
    economic_factors: Record<string, Record<string, number>>;
    industry_growth: {
        past_five_years: string;
        next_five_years_projection: string;
    };
    pricing_trends: {
        average_pricing: Record<string, number>;
        future_pricing_projections: Record<string, number>;
    };
    marketing_and_advertising: {
        channels: Record<string, number>;
    };
    consumer_behavior: {
        preferences: {
            age_group: Record<string, number>;
        };
    };
    source_links: Record<string, string>;
}

interface Product {
  image: string;
  title: string;
  price: string;
  link: string;
}

interface DetailedPlan {
  name: string;
  products: Product[];
  businessPlan: {
    businessName: string;
    location: string;
    missionStatement: string;
    uniqueSellingPoint: string;
    targetDemographic: string;
    competitors: Array<{ name: string; url: string }>;
    marketTrends: MarketTrendsData;
    businessIdea: string;
    indepthBusinessPlan: string;
  };
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const PieChartComponent: React.FC<{ data: any[], colors: string[] }> = ({ data, colors }) => {
  return (
    <div className="pie-chart-container">
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
      <div className="pie-chart-labels">
        {data.map((entry, index) => (
          <div key={`label-${index}`} className="pie-chart-label">
            <span>
              <span style={{ color: colors[index % colors.length] }}>●</span> {entry.name}
            </span>
            <span>{((entry.value / data.reduce((sum, e) => sum + e.value, 0)) * 100).toFixed(1)}%</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const SavedDetailedPlan: React.FC = () => {
  const { planName } = useParams<{ planName: string }>();
  const { session } = useSession();
  const [plan, setPlan] = useState<DetailedPlan | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);


  useEffect(() => {
    const fetchPlan = async () => {
      if (!session) {
        setError('No active session');
        setLoading(false);
        return;
      }

      try {
        const token = await session.getToken();
        const response = await axios.get<DetailedPlan>(`${API_BASE_URL}/api/users/auth/detailed-plan/${encodeURIComponent(planName as string)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        });
        setPlan(response.data);
      } catch (error) {
        setError('Failed to fetch plan. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, [planName, session]);

  const renderMarketTrends = () => {
    if (!plan || !plan.businessPlan.marketTrends) return null;

    const { marketTrends } = plan.businessPlan;

    // Transform economic factors data
    const years = Object.keys(marketTrends.economic_factors.inflation);
    const economicFactorsData = years.map(year => ({
      year,
      inflation: marketTrends.economic_factors.inflation[year],
      interest_rates: marketTrends.economic_factors.interest_rates[year],
      unemployment: marketTrends.economic_factors.unemployment[year]
    }));

    const chartVariants = {
      hidden: { opacity: 0, y: 50 },
      visible: { opacity: 1, y: 0 }
    };

    // Update this part in the renderMarketTrends function
    const pricingData = Object.keys({
      ...marketTrends.pricing_trends.average_pricing,
      ...marketTrends.pricing_trends.future_pricing_projections
    }).map(service => ({
      service,
      currentPrice: marketTrends.pricing_trends.average_pricing[service] || null,
      futurePrice: marketTrends.pricing_trends.future_pricing_projections[service] || null
    }));

    return (
      <div className="main-container market-trends">
        <h2 className="section-header">Market Trends</h2>
        <div className="market-trends-grid">
          <motion.div
            className="chart-container"
            variants={chartVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5 }}
          >
            <h3>Economic Factors</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={economicFactorsData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="year" />
                <YAxis unit="%" />
                <Tooltip formatter={(value, name) => [`${value}%`, name]} />
                <Line type="monotone" dataKey="inflation" stroke={COLORS[0]} />
                <Line type="monotone" dataKey="interest_rates" stroke={COLORS[1]} />
                <Line type="monotone" dataKey="unemployment" stroke={COLORS[2]} />
              </LineChart>
            </ResponsiveContainer>
          </motion.div>

          <motion.div
            className="chart-container"
            variants={chartVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h3>Pricing Trends</h3>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart data={pricingData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="service" />
                <YAxis />
                <Tooltip />
                <Line type="monotone" dataKey="currentPrice" stroke={COLORS[0]} name="Current Pricing" connectNulls />
                <Line type="monotone" dataKey="futurePrice" stroke={COLORS[1]} name="Future Projection" connectNulls />
              </LineChart>
            </ResponsiveContainer>
          </motion.div>

          <motion.div
            className="chart-container"
            variants={chartVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.4 }}
          >
            <h3>Marketing Channels</h3>
            <PieChartComponent 
              data={Object.entries(marketTrends.marketing_and_advertising.channels).map(([channel, value]) => ({ name: channel, value }))}
              colors={COLORS}
            />
          </motion.div>

          <motion.div
            className="chart-container"
            variants={chartVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.6 }}
          >
            <h3>Consumer Behavior - Age Group Preferences</h3>
            <PieChartComponent 
              data={Object.entries(marketTrends.consumer_behavior.preferences.age_group).map(([age, value]) => ({ name: age, value }))}
              colors={COLORS}
            />
          </motion.div>

          <motion.div
            className="chart-container resources-container"
            variants={chartVariants}
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <h3>Resources</h3>
            <div className="source-links-disclaimer">Some information may be incorrect and generated by AI. Please verify information before using.</div>
            <div className="resources-options">
              {Object.entries(marketTrends.source_links).map(([key, url]) => (
                <Link 
                  key={key} 
                  to={url} 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="name-option"
                >
                  {key}
                </Link>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    );
  };

  const formatBusinessPlan = (text: string | undefined): string => {
    if (!text || typeof text !== 'string') return '';
    return text
      .replace(/\n/g, '<br />')
      .replace(/(\d+\.\s)/g, '<strong>$1</strong>')
      .replace(/-\s/g, '<br />&nbsp;&nbsp;&nbsp;&nbsp;- ');
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleBusinessPlanChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (plan) {
      setPlan({
        ...plan,
        businessPlan: {
          ...plan.businessPlan,
          indepthBusinessPlan: e.target.value
        }
      });
    }
  };

  const handleSave = async () => {
    if (!session || !plan) {
      setError('No active session or plan');
      return;
    }

    try {
      setLoading(true);
      const token = await session.getToken();
      await axios.put(`${API_BASE_URL}/api/users/auth/update-detailed-plan/${encodeURIComponent(planName as string)}`, 
        { businessPlan: plan.businessPlan },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        }
      );
      setIsEditing(false);
      setError(null);
    } catch (error) {
      setError('Failed to save changes. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  if (loading) return <LoadingPopup />;
  if (error) return <div className="error-message">{error}</div>;
  if (!plan) return <div className="not-found-message">Plan not found</div>;

  return (
    <div className="saved-detailed-plan">
      
    

      {/* Business Plan Summary */}
      <div className="business-plan-summary">
        <div className="main-container">
          <div className="header">
            <h2 className="business-name">{plan.businessPlan.businessName}</h2>
            <p className="location">{plan.businessPlan.location}</p>
          </div>
          <p className="mission-statement">{plan.businessPlan.missionStatement}</p>
        </div>

        <div className="main-container info-grid">
          <div className="info-item">
            <h3 className="summary_section-header">Target Demographic</h3>
            <p className="summary_section-content">{plan.businessPlan.targetDemographic}</p>
          </div>
          <div className="info-item">
            <h3 className="summary_section-header">Unique Selling Point</h3>
            <p className="summary_section-content">{plan.businessPlan.uniqueSellingPoint}</p>
          </div>
          <div className="info-item">
            <h3 className="summary_section-header">Competition Overview</h3>
            <div className="name-options">
                {plan.businessPlan.competitors.map((competitor, index) => (
                <button
                    key={index}
                    className="name-option"
                    onClick={() => window.open(competitor.url, '_blank')}
                >
                    {competitor.name}
                </button>
                ))}
            </div>
          </div>
        </div>

        {renderMarketTrends()}

        {/* Products Section */}
        <div className="results-main-container" style={{ marginTop: '2rem' }}>
            <h2 className="section-header">Products</h2>
            <div className="product-grid">
            {plan.products && plan.products.length > 0 ? (
                plan.products.map((product, index) => (
                <div key={index} className="product-card">
                    <img src={product.image} alt={product.title} className="product-image" />
                    <h2 className="product-title">{product.title}</h2>
                    <p className="product-price">{product.price}</p>
                    <a href={product.link} className="product-link" target="_blank" rel="noopener noreferrer">
                    View Product
                    </a>
                </div>
                ))
            ) : (
                <p className="no-products-message">No products available.</p>
            )}
            </div>
        </div>

        <div className="main-container">
          <h2 className="section-header">In-Depth Business Plan</h2>
          <div className="business-plan">
            {isEditing ? (
              <textarea
                value={plan.businessPlan.indepthBusinessPlan}
                onChange={handleBusinessPlanChange}
                rows={10}
                className="business-plan-textarea"
                style={{ width: '100%', height: '100%' }}
              />
            ) : (
              <div 
                className="business-plan-text" 
                dangerouslySetInnerHTML={{ __html: formatBusinessPlan(plan.businessPlan.indepthBusinessPlan) }} 
              />
            )}
            <FontAwesomeIcon 
              icon={isEditing ? faSave : faEdit} 
              onClick={isEditing ? handleSave : handleEditToggle} 
              className="edit-icon" 
              style={{ color: 'blue', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SavedDetailedPlan;
