import React, { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import '../css/forumNavVertical.css'; // Import your CSS for styling
import BottomNav from './BottomNav'; // Import the BottomNav component

const ForumNavVertical: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Track if the screen is mobile

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Update mobile state based on window width
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize); // Add resize event listener
    return () => {
      window.removeEventListener('resize', handleResize); // Clean up the event listener
    };
  }, []);

  return (
    <div>
        <div className={`vertical-nav-container ${showMenu ? 'active' : ''}`}>
            <nav className="vertical-navbar">
                <NavLink to="/forum" className="nav-link" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25" />
                    </svg>
                    {window.innerWidth > 768 && <span>Home</span>}
                </NavLink>
                <NavLink to="/forum/create" className="nav-link" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                    </svg>
                    {window.innerWidth > 768 && <span>Create</span>}
                </NavLink>
                <NavLink to="/forum/connections" className="nav-link" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15 19.128a9.38 9.38 0 0 0 2.625.372 9.337 9.337 0 0 0 4.121-.952 4.125 4.125 0 0 0-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 0 1 8.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0 1 11.964-3.07M12 6.375a3.375 3.375 0 1 1-6.75 0 3.375 3.375 0 0 1 6.75 0Zm8.25 2.25a2.625 2.625 0 1 1-5.25 0 2.625 2.625 0 0 1 5.25 0Z" />
                    </svg>
                    {window.innerWidth > 768 && <span>Connections</span>}
                </NavLink>
                <NavLink to="/forum/legal" className="nav-link legal-link" onClick={toggleMenu}>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="icon">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 2v20m-6-6h12M6 6h12" />
                    </svg>
                    {window.innerWidth > 768 && <span>Legal</span>}
                </NavLink>
            </nav>
        </div>
        {isMobile && <BottomNav />} {/* Only display BottomNav for mobile screens */}
    </div>
  );
};

export default ForumNavVertical;