import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useSession } from "@clerk/clerk-react";
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons';
import '../css/results.css';
import config from '../config';
import LoadingPopup from './LoadingPopup';

const API_BASE_URL = config.API_BASE_URL;

interface Product {
  image: string;
  title: string;
  price: string;
  link: string;
}

interface Plan {
  name: string;
  products: Product[];
  businessPlan: string;
}

const SavedPlan: React.FC = () => {
  const { planName } = useParams<{ planName: string }>();
  const { session } = useSession();
  const [plan, setPlan] = useState<Plan | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [businessPlan, setBusinessPlan] = useState('');

  useEffect(() => {
    const fetchPlan = async () => {
      if (!session) {
        setError('No active session');
        setLoading(false);
        return;
      }

      try {
        const token = await session.getToken();
        const response = await axios.get<Plan>(`${API_BASE_URL}/api/users/auth/plan/${encodeURIComponent(planName as string)}`, {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        });
        setPlan(response.data);
        setBusinessPlan(response.data.businessPlan || '');
      } catch (error) {
        setError('Failed to fetch plan. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchPlan();
  }, [planName, session]);

  const formatBusinessPlan = (text: string) => {
    if (!text) return '';
    return text
      .replace(/\n/g, '<br />')
      .replace(/(\d+\.\s)/g, '<strong>$1</strong>')
      .replace(/-\s/g, '<br />&nbsp;&nbsp;&nbsp;&nbsp;- ');
  };

  const handleEditToggle = () => {
    setIsEditing(!isEditing);
  };

  const handleBusinessPlanChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setBusinessPlan(e.target.value);
  };

  const handleSave = async () => {
    if (!session) {
      setError('No active session');
      return;
    }

    try {
      setLoading(true);
      const token = await session.getToken();
      await axios.put(`${API_BASE_URL}/api/users/auth/update-plan/${encodeURIComponent(planName as string)}`, 
        { businessPlan },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'ngrok-skip-browser-warning': 'true'
          }
        }
      );
      setIsEditing(false);
    } catch (error) {
      setError('Failed to save changes. Please try again later.');
    } finally {
      setLoading(false);
    }
  };



  if (loading) return <LoadingPopup />;
  if (error) return <div className="error-message">{error}</div>;
  if (!plan) return <div className="not-found-message">Plan not found</div>;

  return (
    <div className="Results">
      <h1>{plan.name}</h1>
      <div className="action-buttons">
      </div>
      <div className="product-grid">
        {plan.products && plan.products.length > 0 ? (
          plan.products.map((product, index) => (
            <div key={index} className="product-card">
              <img src={product.image} alt={product.title} className="product-image" />
              <h2 className="product-title">{product.title}</h2>
              <p className="product-price">{product.price}</p>
              <a href={product.link} className="product-link" target="_blank" rel="noopener noreferrer">
                View Product
              </a>
            </div>
          ))
        ) : (
          <p className="no-products-message">No products available.</p>
        )}
      </div>
      <div className="business-plan">
        <h2>Business Plan:</h2>
        <FontAwesomeIcon
          icon={isEditing ? faSave : faEdit} 
          onClick={handleEditToggle} 
          className="edit-icon-1" 
        />
        {isEditing ? (
          <>
            <textarea
              value={businessPlan}
              onChange={handleBusinessPlanChange}
              rows={10}
              className="business-plan-textarea"
            />
            <button onClick={handleSave} className="save-button">
              <FontAwesomeIcon icon={faSave} /> Save Changes
            </button>
          </>
        ) : (
          <div 
            className="business-plan-text" 
            dangerouslySetInnerHTML={{ __html: formatBusinessPlan(businessPlan) }} 
          />
        )}
      </div>
    </div>
  );
};

export default SavedPlan;