import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSession } from '@clerk/clerk-react';
import config from '../config';

const API_BASE_URL = config.API_BASE_URL;

interface MarketTrendsData {
    economic_factors: Record<string, Record<string, number>>;
    industry_growth: {
        past_five_years: string;
        next_five_years_projection: string;
    };
    pricing_trends: {
        average_pricing: Record<string, number>;
        future_pricing_projections: Record<string, number>;
    };
    marketing_and_advertising: {
        channels: Record<string, number>;
    };
    consumer_behavior: {
        preferences: {
            age_group: Record<string, number>;
        };
    };
    source_links: Record<string, string>;
}

interface Product {
  image: string;
  title: string;
  price: string;
  link: string;
}

interface DetailedPlan {
  name: string;
  products: Product[];
  businessPlan: {
    businessName: string;
    location: string;
    missionStatement: string;
    uniqueSellingPoint: string;
    targetDemographic: string;
    competitors: Array<{ name: string; url: string }>;
    marketTrends: MarketTrendsData;
    businessIdea: string;
    indepthBusinessPlan: string;
  };
}

interface ServicePlan {
    name: string;
    businessPlan: {
        businessName: string;
        location: string;
        missionStatement: string;
        uniqueSellingPoint: string;
        targetDemographic: string;
        competitors: Array<{ name: string; url: string }>;
        marketTrends: MarketTrendsData;
        businessIdea: string;
        indepthBusinessPlan: string;
    };
}

interface SavedPlan {
    name: string;
    products: Product[];

}

export interface UserProfile {
  clerkId: string;
  username: string;
  email: string;
  membershipType: string;
  subscriptionStatus: string;
  savedPlans: SavedPlan[];
  savedServicePlans: ServicePlan[];
  savedDetailedPlans: DetailedPlan[];
  premiumGenerations: number;
  generationsToday: number;
  cancellationDate: string | null;
}

interface FetchUserProfileProps {
  children: (profile: UserProfile | null, isLoading: boolean, error: string | null) => React.ReactNode;
}

const FetchUserProfile: React.FC<FetchUserProfileProps> = ({ children }) => {
  const [profile, setProfile] = useState<UserProfile | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const { session } = useSession();

  const fetchProfile = useCallback(async () => {
    if (!session) {
      setError('Session not found');
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const token = await session.getToken();
      const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
        headers: { 
          Authorization: `Bearer ${token}`,
          'ngrok-skip-browser-warning': 'true'
        }
      });
      setProfile(response.data);
      setError(null);
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An unknown error occurred');
    } finally {
      setIsLoading(false);
    }
  }, [session]);

  useEffect(() => {
    if (!profile) {
      fetchProfile();
    }
  }, [fetchProfile, profile]);

  return <>{children(profile, isLoading, error)}</>;
};

export default FetchUserProfile;
