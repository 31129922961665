import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession, useUser } from "@clerk/clerk-react";
import axios from 'axios';
import "../css/PaymentButtons.css";

import config from '../config';

const API_BASE_URL = config.API_BASE_URL;

interface PaymentButtonsProps {
    subType: string;
    userMembership: string;
}

const PaymentButtons: React.FC<PaymentButtonsProps> = ({ subType }) => {
    const navigate = useNavigate();
    const { session } = useSession();
    const { user } = useUser();
    const [membershipType, setUserMembership] = useState<string>('free');
    const [subscriptionStatus, setSubscriptionStatus] = useState<string>('free');

    const fetchUserData = async () => {
        if (session) {
            try {
                const token = await session.getToken();
                const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                const { membershipType, subscriptionStatus } = response.data;
                setUserMembership(membershipType || 'free');
                setSubscriptionStatus(subscriptionStatus || 'free');
            } catch (error) {
                console.error('Error fetching user data');
            }
        }
    };

    useEffect(() => {
        fetchUserData();
    }, [session]);

    const handlePayment = async () => {
        if (!session || !user) {
            console.error("No active session or user");
            return;
        }

        if (subType === 'downgrade' && membershipType === 'free') {
            alert('You are already on the free plan.');
            return;
        }

        if ((subType === 'monthly' || subType === 'yearly') && membershipType === 'premium' && subscriptionStatus === 'active') {
            alert('You are already a premium member.');
            return;
        }

        try {
            const token = await session.getToken();
        
            if (subType === 'downgrade') {
                await axios.post(`${API_BASE_URL}/api/payment/cancel-subscription`, {}, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                alert('Your subscription will be canceled at the end of the billing period.');
                
                // Refresh user data
                await fetchUserData();
                navigate('/profile');
            } else {
                // For upgrade (monthly or yearly), create a checkout session
                const response = await axios.post(`${API_BASE_URL}/api/payment/create-checkout-session`, {
                    subType,
                    userId: user.id,
                    isDetailedPlan: false
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                
                // Redirect to Stripe Checkout
                window.location.href = response.data.url;
            }
        } catch (error) {
            console.error('Error during payment process');
            alert('An error occurred during the payment process. Please try again.');
        }
    };  

    return (
        <div className='upgrade-container'>
            <div className={`button-wrapper ${subType === 'downgrade' ? 'downgrade-button-wrapper' : 'upgrade-button-wrapper'}`}>
                <button 
                onClick={handlePayment} 
                className='payment-button'
                >
                    {subType === 'downgrade' ? 'Downgrade to Free' : `Upgrade to Premium`}
                </button>
            </div>
        </div>
    );
};

export default PaymentButtons;