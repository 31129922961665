import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import '../css/CreateAPost.css';
import FetchUserProfile, { UserProfile } from '../FetchUserProfile';
import { useAuth } from '@clerk/clerk-react';

const API_BASE_URL = config.API_BASE_URL;

const categories = [
    "E-commerce", "Law", "Finance", "Healthcare", "Education",
    "Technology", "Manufacturing", "Real Estate", "Marketing",
    "Travel & Hospitality", "Retail", "Entertainment", "Construction",
    "Food & Beverage", "Transport & Logistics", "Energy", "Agriculture", "Fashion"
];

interface PostData {
    title: string;
    content: string;
    author: string;
    categories: string[];
}

interface PostFormProps {
    profile: UserProfile;
    onSubmit: (postData: PostData) => Promise<void>;
    formState: {
        title: string;
        selectedCategories: string[];
        body: string;
    };
    setFormState: React.Dispatch<React.SetStateAction<PostFormProps['formState']>>;
}

const PostForm: React.FC<PostFormProps> = React.memo(({ profile, onSubmit, formState, setFormState }) => {
    const navigate = useNavigate();

    const handleCategoryChange = (category: string) => {
        setFormState(prev => ({
            ...prev,
            selectedCategories: prev.selectedCategories.includes(category)
                ? prev.selectedCategories.filter(c => c !== category)
                : [...prev.selectedCategories, category]
        }));
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (formState.title.trim() === '' || formState.body.trim() === '') {
            alert('Title and Body are required');
            return;
        }
    
        const postData: PostData = {
            title: formState.title,
            content: formState.body,
            categories: formState.selectedCategories,
            author: profile.username
        };
    
        await onSubmit(postData);
    };
    

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-group">
                <label>Title</label>
                <input
                    type="text"
                    value={formState.title}
                    onChange={(e) => setFormState(prev => ({ ...prev, title: e.target.value }))}
                    required
                    className="input-title"
                    placeholder="Enter the title of your post"
                />
            </div>
            <hr className="separator" />
            <div className="form-group">
                <label>Categories</label>
                <div className="category-container">
                    {categories.map(category => (
                        <span
                            key={category}
                            className={`category-badge ${formState.selectedCategories.includes(category) ? 'selected' : ''}`}
                            onClick={() => handleCategoryChange(category)}
                        >
                            {category}
                        </span>
                    ))}
                </div>
            </div>
            <hr className="separator" />
            <div className="form-group">
                <label>Body</label>
                <textarea
                    value={formState.body}
                    onChange={(e) => setFormState(prev => ({ ...prev, body: e.target.value }))}
                    required
                    className="input-body"
                    placeholder="Enter the body of your post"
                />
            </div>
            <div className="button-container">
                <button type="submit" className="button-post">Post</button>
                <button type="button" onClick={() => navigate('/forum')} className="button-cancel">Cancel</button>
            </div>
        </form>
    );
});

const CreateAPost: React.FC = () => {
    const { getToken } = useAuth();
    const navigate = useNavigate();
    const [formState, setFormState] = useState({
        title: '',
        selectedCategories: [] as string[],
        body: '',
    });

    const handleSubmit = useCallback(async (postData: PostData) => {
        try {
            const token = await getToken();
            await axios.post(`${API_BASE_URL}/api/forum/posts`, postData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json'
                }
            });
            navigate('/forum');
        } catch (error) {
            alert('Failed to create post. Please try again.');
        }
    }, [getToken, navigate]);

    const renderPostForm = useCallback((profile: UserProfile) => (
        <PostForm
            profile={profile}
            onSubmit={handleSubmit}
            formState={formState}
            setFormState={setFormState}
        />
    ), [formState, handleSubmit]);

    return (
        <FetchUserProfile>
            {(profile, isLoading, error) => (
                <div className="create-post-container">
                    <h1>Create a Post</h1>
                    {isLoading ? (
                        <div>Loading profile...</div>
                    ) : error ? (
                        <div>{error}</div>
                    ) : !profile ? (
                        <div>No profile data available</div>
                    ) : (
                        renderPostForm(profile)
                    )}
                </div>
            )}
        </FetchUserProfile>
    );
};

export default CreateAPost;