import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import PostModal from '../objects/PostModal';
import config from '../../config';

const API_BASE_URL = config.API_BASE_URL;

interface SearchResult {
  _id: string;
  title: string;
  content: string;
  author: {
    username: string;
  };
  categories: string[];
  likes: number;
  dislikes: number;
  pinned: boolean;
}

const SearchResults: React.FC = () => {
  const [results, setResults] = useState<SearchResult[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const query = searchParams.get('q');

    if (query) {
      setLoading(true);
      setError(null);
      setResults(null);
      axios.get(`${API_BASE_URL}/api/forum/search?query=${encodeURIComponent(query)}`)
        .then(response => {
          setResults(response.data.posts || []);
          setLoading(false);
        })
        .catch(() => {
          console.error('Error fetching search results:');
          setError('Failed to fetch search results');
          setLoading(false);
        });
    } else {
      setError('No search query provided');
      setLoading(false);
    }
  }, [location.search]);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (results === null) return null;

  return (
    <div className="search-results">
      <h2>Search Results</h2>
      {results.length === 0 ? (
        <p>No results found.</p>
      ) : (
        results.map(post => (
          <PostModal
            key={post._id}
            _id={post._id}
            title={post.title}
            categories={post.categories}
            content={post.content}
            author={post.author}
            likes={post.likes}
            dislikes={post.dislikes}
            pinned={post.pinned}
          />
        ))
      )}
    </div>
  );
};

export default SearchResults;