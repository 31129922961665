import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import MessageModal from './MessageModal';
import './css/InboxMessageModal.css';

interface InboxMessageModalProps {
  sender: {
    username: string;
    profilePicture: string;
  };
  content: string;
  timestamp: string;
  onDelete: () => void;
}

const InboxMessageModal: React.FC<InboxMessageModalProps> = ({ sender, content, timestamp, onDelete }) => {
  const [expanded, setExpanded] = useState(false);
  const [showReplyModal, setShowReplyModal] = useState(false);
  const [showExpandButton, setShowExpandButton] = useState(false);
  const textRef = useRef<HTMLParagraphElement>(null);

  useEffect(() => {
    if (textRef.current) {
      setShowExpandButton(textRef.current.scrollHeight > textRef.current.clientHeight);
    }
  }, [content]);

  const toggleExpand = () => setExpanded(!expanded);
  const handleReply = () => setShowReplyModal(true);
  const handleCloseReplyModal = () => setShowReplyModal(false);

  return (
    <div className="inbox-message-modal">
      <div className="inbox-message-avatar">
        <img src={sender.profilePicture || `https://api.dicebear.com/6.x/initials/svg?seed=${sender.username}`} alt={`${sender.username}'s avatar`} />
      </div>
      <div className="inbox-message-content">
        <div className="inbox-message-header">
          <Link to={`/forum/user/${sender.username}`} className="inbox-message-username">
            {sender.username}
          </Link>
          <span className="inbox-message-timestamp">{timestamp}</span>
        </div>
        <div className={`inbox-message-text-container ${expanded ? 'expanded' : ''}`}>
          <p ref={textRef} className="inbox-message-text">
            {content}
          </p>
        </div>
        {showExpandButton && (
          <button className="inbox-message-expand" onClick={toggleExpand}>
            {expanded ? 'Show less' : 'Show more'}
          </button>
        )}
      </div>
      <div className="inbox-message-actions">
        <button className="inbox-message-reply" onClick={handleReply}>
          Reply
        </button>
        <button className="inbox-message-delete" onClick={onDelete}>
          Delete
        </button>
      </div>
      {showReplyModal && (
        <MessageModal
          recipientUsername={sender.username}
          onClose={handleCloseReplyModal}
        />
      )}
    </div>
  );
};

export default InboxMessageModal;
