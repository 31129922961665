import React, { useState, useEffect } from 'react';
import { useSession, useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import config from '../config';
import './css/AnalyzeMarket.css';

const API_BASE_URL = config.API_BASE_URL;

interface MarketTrendsData {
    industry_growth: {
        past_five_years: string;
        next_five_years_projection: string;
    };
    consumer_behavior: {
        preferences: {
            age_group: {
                [key: string]: number;
            };
        };
    };
    economic_factors: {
        inflation: { [year: string]: number };
        interest_rates: { [year: string]: number };
        unemployment: { [year: string]: number };
    };
    pricing_trends: {
        average_pricing: { [year: string]: number };
        future_pricing_projections: { [year: string]: number };
    };
    marketing_and_advertising: {
        channels: {
            [channel: string]: number;
        };
    };
    source_links: {
        [name: string]: string;
    };
}

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8'];

const AnalyzeMarket: React.FC = () => {
    const { session } = useSession();
    const { user } = useUser();
    const [businessIdea, setBusinessIdea] = useState('');
    const [marketTrends, setMarketTrends] = useState<MarketTrendsData | null>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [generationsRemaining, setGenerationsRemaining] = useState<number | null>(null);
    const [membershipType, setMembershipType] = useState<string>('');

    useEffect(() => {
        fetchUserProfile();
    }, [user, session]);

    const fetchUserProfile = async () => {
        if (user && session) {
            try {
                setIsLoading(true);
                const token = await session.getToken();
                const response = await axios.get(`${API_BASE_URL}/api/users/auth/profile`, {
                    headers: { 
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                
                const profile = response.data;
                const clerkMembershipType = profile.membershipType as string;
                
                // Use Clerk metadata if available, otherwise use the profile from the API
                const finalMembershipType = clerkMembershipType || profile.membershipType;
                setMembershipType(finalMembershipType);
                
                if (finalMembershipType === 'free') {
                    const maxGenerations = 3;
                    const generationsToday = user.publicMetadata.generationsToday as number || profile.generationsToday || 0;
                    const remaining = maxGenerations - generationsToday;
                    setGenerationsRemaining(remaining);
                } else {
                    setGenerationsRemaining(null);
                }
            } catch (error) {
                setError('An error occurred while fetching user profile. Please try refreshing the page.');
            } finally {
                setIsLoading(false);
            }
        } else {
            setIsLoading(false);
        }
    };

    const handleAnalyze = async () => {
        if (!session) {
            setError('You must be signed in to use this feature.');
            return;
        }

        if (membershipType === 'free' && (generationsRemaining === null || generationsRemaining <= 0)) {
            setError("You have reached your daily generation limit. Please try again tomorrow or upgrade to premium.");
            return;
        }

        setIsLoading(true);
        setError(null);

        try {
            const token = await session.getToken();
            
            // First, decrement the user's daily generations if they're a free user
            if (membershipType === 'free') {
                const decrementResponse = await axios.post(
                    `${API_BASE_URL}/api/users/auth/decrement-daily-generations`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'ngrok-skip-browser-warning': 'true'
                        }
                    }
                );

                if (decrementResponse.data.error) {
                    setError(decrementResponse.data.error);
                    setGenerationsRemaining(0);
                    setIsLoading(false);
                    return;
                }
            }

            // Proceed with market trends analysis
            const response = await axios.post(
                `${API_BASE_URL}/api/ideas/identify-market-trends`,
                { 
                    businessIdea,
                    businessName: 'Sample Business',
                    location: 'Global',
                    targetDemographic: 'General'
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                }
            );
            
            setMarketTrends(response.data);

            // Update local state for generations remaining
            if (membershipType === 'free') {
                const newGenerationsRemaining = generationsRemaining !== null ? generationsRemaining - 1 : null;
                setGenerationsRemaining(newGenerationsRemaining);
            }

        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.status === 403) {
                setError("You have reached your daily generation limit. Please try again tomorrow or upgrade to premium.");
                setGenerationsRemaining(0);
            } else {
                setError('Failed to analyze market trends. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const renderMarketTrends = () => {
        if (!marketTrends) return null;

        const years = Object.keys(marketTrends.economic_factors.inflation);
        const economicFactorsData = years.map(year => ({
            year,
            inflation: marketTrends.economic_factors.inflation[year],
            interest_rates: marketTrends.economic_factors.interest_rates[year],
            unemployment: marketTrends.economic_factors.unemployment[year]
        }));

        const chartVariants = {
            hidden: { opacity: 0, y: 50 },
            visible: { opacity: 1, y: 0 }
        };

        return (
            <div className="market-trends-results-1">
                <h2>Market Trends Analysis</h2>
                
                <motion.div 
                    className="chart-container-1"
                    variants={chartVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5 }}
                >
                    <h3>Industry Growth</h3>
                    <p>Past 5 Years: {marketTrends.industry_growth.past_five_years}</p>
                    <p>Next 5 Years Projection: {marketTrends.industry_growth.next_five_years_projection}</p>
                </motion.div>

                <motion.div 
                    className="chart-container-1"
                    variants={chartVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5, delay: 0.2 }}
                >
                    <h3>Economic Factors</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <LineChart data={economicFactorsData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Line type="monotone" dataKey="inflation" stroke={COLORS[0]} />
                            <Line type="monotone" dataKey="interest_rates" stroke={COLORS[1]} />
                            <Line type="monotone" dataKey="unemployment" stroke={COLORS[2]} />
                        </LineChart>
                    </ResponsiveContainer>
                </motion.div>

                <motion.div 
                    className="chart-container-1"
                    variants={chartVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5, delay: 0.4 }}
                >
                    <h3>Marketing and Advertising Channels</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={Object.entries(marketTrends.marketing_and_advertising.channels).map(([name, value]) => ({ name, value }))}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {Object.entries(marketTrends.marketing_and_advertising.channels).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </motion.div>

                <motion.div 
                    className="chart-container-1"
                    variants={chartVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5, delay: 0.6 }}
                >
                    <h3>Consumer Behavior - Age Group Preferences</h3>
                    <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                            <Pie
                                data={Object.entries(marketTrends.consumer_behavior.preferences.age_group).map(([age, value]) => ({ name: age, value }))}
                                cx="50%"
                                cy="50%"
                                labelLine={false}
                                outerRadius={80}
                                fill="#8884d8"
                                dataKey="value"
                                label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
                            >
                                {Object.entries(marketTrends.consumer_behavior.preferences.age_group).map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                ))}
                            </Pie>
                            <Tooltip />
                        </PieChart>
                    </ResponsiveContainer>
                </motion.div>

                <motion.div 
                    className="chart-container-1 resources-container"
                    variants={chartVariants}
                    initial="hidden"
                    animate="visible"
                    transition={{ duration: 0.5, delay: 0.8 }}
                >
                    <h3>Resources</h3>
                    <div className="source-links-disclaimer">Some information may be incorrect and generated by AI. Please verify information before using.</div>
                    <div className="resources-options">
                        {Object.entries(marketTrends.source_links).map(([key, url]) => (
                            <Link 
                                key={key} 
                                to={url} 
                                target="_blank" 
                                rel="noopener noreferrer" 
                                className="name-option"
                            >
                                {key}
                            </Link>
                        ))}
                    </div>
                </motion.div>
            </div>
        );
    };

    return (
        <div className="analyze-market-page">
            <div className="analyze-market-container">
                <div className="analyze-market-content">
                    <h1 className="main-text">AI Market Trend Analyzer</h1>
                    <p className="paragraph-text">Discover market trends for your business idea!</p>
                    {generationsRemaining !== null && (
                        <p className="generations-remaining">
                            Generations remaining today: {generationsRemaining} / 3
                        </p>
                    )}
                    <div className="search-container">
                        <div className="search-bar-wrapper">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="search-icon">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z" />
                            </svg>
                            <input
                                type="text"
                                placeholder="Enter your business idea"
                                className="search-bar"
                                value={businessIdea}
                                onChange={(e) => setBusinessIdea(e.target.value)}
                                onKeyPress={(e) => e.key === 'Enter' && handleAnalyze()}
                                aria-label="Enter your business idea"
                            />
                        </div>
                        <button 
                            onClick={handleAnalyze} 
                            className="go-button" 
                            disabled={isLoading || (generationsRemaining !== null && generationsRemaining <= 0)}
                        >
                            {isLoading ? 'Analyzing...' : 'Analyze'}
                        </button>
                    </div>

                    {error && <p className="error-message">{error}</p>}

                    {marketTrends && renderMarketTrends()}
                </div>
            </div>
        </div>
    );
};

export default AnalyzeMarket;
