import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import '../../css/ServicePlan.css';
import '../../css/steps/BusinessOverview.css';
import { useSession } from "@clerk/clerk-react";
import { saveStepData } from '../../utils/localStorageUtils';

const API_BASE_URL = config.API_BASE_URL;

interface BusinessOverviewProps {
    membershipType: string;
    businessIdea: string;
    onNextStep: () => void;
    setBusinessName: (name: string) => void;
    setMissionStatement: (statement: string) => void;
    setLocation: (location: string) => void;
}

const RefreshIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="refresh-icon">
        <path strokeLinecap="round" strokeLinejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
    </svg>
);

const BusinessOverview: React.FC<BusinessOverviewProps> = ({ membershipType, businessIdea, onNextStep, setBusinessName, setMissionStatement, setLocation }) => {    const { session } = useSession();
    const location = useLocation();
    const [businessNames, setBusinessNames] = useState<string[]>([]);
    const [selectedName, setSelectedName] = useState<string>('');
    const [customName, setCustomName] = useState<string>('');
    const [localMissionStatement, setLocalMissionStatement] = useState('');
    const [selectedMissionStatement, setSelectedMissionStatement] = useState('');
    const [generatedMissionStatements, setGeneratedMissionStatements] = useState<string[]>([]);
    const [animateNames, setAnimateNames] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [, setLoading] = useState(false);
    const [localLocation, setLocalLocation] = useState('');
    const { isPremiumGeneration } = location.state as { isPremiumGeneration: boolean };


    useEffect(() => {
        generateBusinessNames();
        console.log(membershipType);
        if ((membershipType === 'premium' || isPremiumGeneration)) {
            generateMissionStatement();
        }
    }, []);

    localStorage.setItem('businessIdea', JSON.stringify(businessIdea));


    const generateBusinessNames = async () => {
        if (!session) {
            setError('No active session');
            return;
        }

        setLoading(true);
        setAnimateNames(false);
        try {
            const token = await session.getToken();
            const response = await axios.post(
                `${API_BASE_URL}/api/ideas/generate-business-names`,
                { businessIdea },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': '69420'
                    }
                }
            );
            setBusinessNames(response.data.businessNames);
            setTimeout(() => setAnimateNames(true), 100);
        } catch (error) {
            setError('Failed to generate business names. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const generateMissionStatement = async () => {
        if (!session || (localMissionStatement || selectedMissionStatement)) return;
        setLoading(true);
        try {
            const token = await session.getToken();
            const response = await axios.post(
                `${API_BASE_URL}/api/ideas/generate-mission-statement`,
                { businessIdea, businessName: selectedName || customName },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': '69420'
                    }
                }
            );
            setGeneratedMissionStatements(prevStatements => [...prevStatements, response.data.missionStatement]);
        } catch (error) {
            setError('Failed to generate mission statement. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleNameSelection = (name: string) => {
        setSelectedName(prevName => prevName === name ? '' : name);
        setCustomName('');
        setBusinessName(name);
    };

    const handleCustomNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setCustomName(name);
        setSelectedName('');
        setBusinessName(name);
    };

    const handleMissionStatementChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const statement = e.target.value;
        setLocalMissionStatement(statement);
        setSelectedMissionStatement('');
        setMissionStatement(statement);
    };

    const handleMissionStatementSelection = (statement: string) => {
        setSelectedMissionStatement(statement);
        setLocalMissionStatement('');
        setMissionStatement(statement);
    };

    const isBusinessNameValid = () => {
        return (selectedName.length >= 3 || customName.length >= 3);
    };

    const isMissionStatementValid = () => {
        return (localMissionStatement.length >= 10 || selectedMissionStatement.length >= 10);
    };

    const canProceedToNextStep = isBusinessNameValid() && isMissionStatementValid() && localLocation.length > 0;

    const handleLocationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const location = e.target.value;
        setLocalLocation(location);
        setLocation(location);
    };

    useEffect(() => {
        const businessData = {
            businessName: selectedName || customName,
            missionStatement: selectedMissionStatement || localMissionStatement,
            location: localLocation
        };
        saveStepData('businessOverview', businessData);
    }, [selectedName, customName, selectedMissionStatement, localMissionStatement, localLocation]);

    if (error) return <div className="error-message">{error}</div>;

    return (
        <div className="business-overview">
            <div className="step-header">Business Overview</div>
            <div className="business-name-selection">
                <div className="classic-section-header">
                    Choose your business name
                </div>
                <div className="name-options">
                    {animateNames && businessNames.map((name, index) => (
                        <button
                            key={index}
                            onClick={() => handleNameSelection(name)}
                            className={`name-option ${selectedName === name ? 'selected' : ''}`}
                        >
                            {name}
                        </button>
                    ))}
                    {(membershipType === 'premium' || isPremiumGeneration) && (
                        <button onClick={generateBusinessNames} className="refresh-button" aria-label="Refresh business names">
                            <RefreshIcon />
                        </button>
                    )}
                </div>
                <div className="custom-name-input">
                    <input
                        type="text"
                        value={customName}
                        onChange={handleCustomNameChange}
                        placeholder="Or enter your own business name (min 3 characters)"
                    />
                </div>
            </div>
            <div className="mission-statement-section">
                <div className="classic-section-header">
                    Mission Statement
                </div>
                <div className="custom-name-input">
                    <input
                        type="text"
                        value={localMissionStatement}
                        onChange={handleMissionStatementChange}
                        placeholder="Enter your mission statement (min 10 characters)"
                    />
                </div>
                {(membershipType === 'premium' || isPremiumGeneration) && (
                    <div className="name-options">
                        {generatedMissionStatements.map((statement, index) => (
                            <button
                                key={index}
                                onClick={() => handleMissionStatementSelection(statement)}
                                className={`name-option ${selectedMissionStatement === statement ? 'selected' : ''}`}
                            >
                                {statement}
                            </button>
                        ))}
                        <button onClick={generateMissionStatement} className="refresh-button" aria-label="Generate mission statement">
                            <RefreshIcon />
                        </button>
                    </div>
                )}
            </div>
            <div className="custom-name-input">
                <div className="classic-section-header">Location</div>
                <div className="location-input">
                    <input
                        type="text"
                        value={localLocation}
                        onChange={handleLocationChange}
                        placeholder="Enter location"
                    />

                </div>
            </div>
            {canProceedToNextStep && (
                <button onClick={onNextStep} className="next-step-button">
                    Continue
                </button>
            )}
        </div>
    );
};

export default BusinessOverview;