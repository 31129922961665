import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div>
            <p>
                PRIVACY POLICY<br/>
                Last updated August 14, 2024<br/>
                <br/>
                <h4><strong>1. INTRODUCTION</strong></h4>
                Welcome to StartupGeniusAI. We are committed to protecting your privacy and ensuring a safe online experience. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website http://www.startupgeniusai.com or use our services. By using our site and services, you agree to the terms of this Privacy Policy.<br/><br/>

                <h4><strong>2. INFORMATION WE COLLECT</strong></h4>
                We collect the following types of information:<br/>
                • Personal Information: When you create an account, we collect personal details such as your name, email address, and any other information you provide.<br/>
                • Usage Data: We collect information about how you interact with our website, including IP address, browser type, operating system, and pages visited.<br/>
                • Transaction Data: If you make a purchase or subscribe to our premium service, we collect payment information via Stripe, including billing details.<br/><br/>

                <h4><strong>3. HOW WE USE YOUR INFORMATION</strong></h4>
                We use your information for the following purposes:<br/>
                • Account Management: To create and manage your account, process logins, and provide access to features.<br/>
                • Service Delivery: To generate product recommendations, manage your memberships, and provide customer support.<br/>
                • Transaction Processing: To process payments and handle billing through Stripe.<br/>
                • Communication: To send you updates, newsletters, and other information related to our services.<br/>
                • Analytics: To analyze website usage and improve our services.<br/><br/>

                <h4><strong>4. INFORMATION SHARING AND DISCLOSURE</strong></h4>
                We do not sell your personal information. We may share your information with:<br/>
                • Service Providers: We use third-party services such as Clerk for authentication, OpenAI and SerpApi for generating product recommendations, and Stripe for payment processing. These providers are bound by confidentiality agreements and use your information only to provide services to us.<br/>
                • Legal Requirements: We may disclose your information if required by law or to respond to legal requests.<br/><br/>

                <h4><strong>5. COOKIES AND TRACKING TECHNOLOGIES</strong></h4>
                We use cookies and similar technologies to enhance your browsing experience, track user activity, and collect analytics data. You can manage your cookie preferences through your browser settings.<br/><br/>

                <h4><strong>6. DATA SECURITY</strong></h4>
                We implement reasonable security measures to protect your personal information from unauthorized access, use, or disclosure. However, no online system is completely secure, and we cannot guarantee absolute security.<br/><br/>

                <h4><strong>7. DATA RETENTION</strong></h4>
                We retain your personal information only as long as necessary to fulfill the purposes outlined in this Privacy Policy or as required by law.<br/><br/>

                <h4><strong>8. YOUR RIGHTS</strong></h4>
                You have the following rights regarding your personal information:<br/>
                • Access: You can request access to the personal information we hold about you.<br/>
                • Correction: You can request correction of inaccurate or incomplete information.<br/>
                • Deletion: You can request deletion of your personal information, subject to certain legal exceptions.<br/>
                • Opt-Out: You can opt out of receiving marketing communications from us.<br/>
                To exercise these rights, please contact us at perkinstech.biz@gmail.com.<br/><br/>

                <h4><strong>9. CHANGES TO THIS PRIVACY POLICY</strong></h4>
                We may update this Privacy Policy from time to time. We will notify you of any significant changes by posting the updated policy on our website. Your continued use of our services constitutes acceptance of the updated policy.<br/><br/>

                <h4><strong>10. CONTACT US</strong></h4>
                If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:<br/>
                Email: perkinstech.biz@gmail.com
            </p>
        </div>
    );
}

export default PrivacyPolicy;