import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSession, useUser } from '@clerk/clerk-react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage } from '@fortawesome/free-solid-svg-icons';
import config from '../../config';
import '../css/UserProfile.css';
import PostModal from '../objects/PostModal';
import MessageModal from '../objects/MessageModal';

const API_BASE_URL = config.API_BASE_URL;

interface UserProfileData {
    username: string;
    imageUrl: string; // Add this line
    posts: {
        _id: string;
        title: string;
        content: string;
        createdAt: string;
        likes: number;
        dislikes: number;
        categories: string[];
        pinned: boolean;
    }[];
}

const StarIcon: React.FC = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="star-icon">
        <path strokeLinecap="round" strokeLinejoin="round" d="M11.48 3.499a.562.562 0 0 1 1.04 0l2.125 5.111a.563.563 0 0 0 .475.345l5.518.442c.499.04.701.663.321.988l-4.204 3.602a.563.563 0 0 0-.182.557l1.285 5.385a.562.562 0 0 1-.84.61l-4.725-2.885a.562.562 0 0 0-.586 0L6.982 20.54a.562.562 0 0 1-.84-.61l1.285-5.386a.562.562 0 0 0-.182-.557l-4.204-3.602a.562.562 0 0 1 .321-.988l5.518-.442a.563.563 0 0 0 .475-.345L11.48 3.5Z" />
    </svg>
);

const OtherUserProfile: React.FC = () => {
    const { username } = useParams<{ username: string }>();
    const { session } = useSession();
    const { user: currentUser } = useUser();
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState<UserProfileData | null>(null);
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [showMessageModal, setShowMessageModal] = useState(false);

    useEffect(() => {
        const fetchUserProfile = async () => {
            if (!session || !username) return;

            // Check if the profile being viewed is the current user's profile
            if (currentUser && currentUser.username === username) {
                navigate('/forum/profile'); // Redirect to the user's own profile page
                return;
            }

            try {
                const token = await session.getToken();
                const response = await axios.get<UserProfileData>(`${API_BASE_URL}/api/forum/users/${username}/profile`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                });
                setProfileData(response.data);
            } catch (error) {
                setError('Failed to fetch user profile');
                console.error('Error fetching user profile');
            } finally {
                setIsLoading(false);
            }
        };

        fetchUserProfile();
    }, [session, username, currentUser, navigate]);

    if (isLoading) {
        return <div>Loading user profile...</div>;
    }

    if (error || !profileData) {
        return <div>Error: {error || 'Failed to load user profile'}</div>;
    }

    const eLevel = profileData.posts.length * 10;

    return (
        <div className="user-profile-container">
            <div className="user-info-grid">
                <img src={profileData.imageUrl || `https://api.dicebear.com/6.x/initials/svg?seed=${profileData.username}`} alt={profileData.username} className="user-avatar" />
                <div className="user-details">
                    <h1>{profileData.username}</h1>
                    <p className="e-level">
                        E<StarIcon /> {eLevel}
                    </p>
                    <button onClick={() => setShowMessageModal(true)} className="message-button">
                        <FontAwesomeIcon icon={faMessage} />
                    </button>
                </div>
            </div>
            <div className="user-posts">
                <h2>User Posts</h2>
                <div className="posts-grid">
                    {profileData.posts.map(post => (
                        <PostModal
                            key={post._id}
                            _id={post._id}
                            title={post.title}
                            categories={post.categories}
                            content={post.content}
                            author={{ username: profileData.username }}
                            likes={post.likes}
                            dislikes={post.dislikes}
                            pinned={post.pinned}
                        />
                    ))}
                </div>
            </div>
            {showMessageModal && (
                <MessageModal
                    recipientUsername={profileData.username}
                    onClose={() => setShowMessageModal(false)}
                />
            )}
        </div>
    );
};

export default OtherUserProfile;