import React, { useState, useEffect } from 'react';
import '../css/LearnMore.css';
import Services from './Services';
import { Link } from 'react-router-dom';
import { SignedIn, SignedOut, useClerk } from "@clerk/clerk-react";
import UserAgreement from './UserAgreenment';
import { motion, useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

const LearnMore: React.FC<{ onSignUpClick: () => void }> = ({ onSignUpClick }) => {
    const { openSignUp } = useClerk();
    const [showUserAgreement, setShowUserAgreement] = useState(false);
  
    const steps = [
      {
        title: "Share Your Vision",
        description: "Enter your business idea and watch it come to life.",
        icon: "💡"
      },
      {
        title: "AI-Powered Analysis",
        description: "Our advanced AI analyzes your concept and gathers crucial data.",
        icon: "🤖"
      },
      {
        title: "Product Recommendations",
        description: "Discover tailored product suggestions to kickstart your business.",
        icon: "🚀"
      },
      {
        title: "Custom Strategy",
        description: "Receive a personalized business plan and growth strategy.",
        icon: "📊"
      },
      {
        title: "Refine and Perfect",
        description: "Use our interactive tools to polish your plan to perfection.",
        icon: "✨"
      },
      {
        title: "Launch with Confidence",
        description: "Start building your dream business backed by expert insights.",
        icon: "🏆"
      }
    ];
  
    const handleSignUp = () => {
        onSignUpClick();
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
  
    const handleUserAgreementSubmit = async (tosAccepted: boolean, privacyAccepted: boolean) => {
      setShowUserAgreement(false);
      
      if (tosAccepted && privacyAccepted) {
        try {
          await openSignUp();
        } catch (err) {
          console.error('Error opening sign up');
        }
      }
    };
  
    const handleUserAgreementCancel = () => {
      setShowUserAgreement(false);
    };
  
    const controls = useAnimation();
    const [ref, inView] = useInView({
        threshold: 0.1,
        triggerOnce: true
    });

    useEffect(() => {
        if (inView) {
            controls.start('visible');
        }
    }, [controls, inView]);

    useEffect(() => {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('show');
          }
        });
      }, { threshold: 0.2 });
  
      document.querySelectorAll('.step').forEach((step) => {
        observer.observe(step);
      });
  
      return () => observer.disconnect();
    }, []);

    useEffect(() => {
        const servicesObserver = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add('show');
            }
          });
        }, { threshold: 0.2 });
      
        document.querySelectorAll('.service-column').forEach((column) => {
          servicesObserver.observe(column);
        });
      
        return () => servicesObserver.disconnect();
      }, []);

    const containerVariants = {
        hidden: {},
        visible: {
            transition: {
                staggerChildren: 0.1
            }
        }
    };

    const cardVariants = {
        hidden: { 
            opacity: 0,
            y: 50,
            scale: 0.8,
            rotate: -5
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            rotate: 0,
            transition: {
                type: "spring",
                damping: 12,
                stiffness: 100,
                duration: 0.5
            }
        }
    };

    return (
      <div className="learn-more-container">
        <section className="hero">
          <h1 className="learn-more-main-title">Make Your<br/><span className="highlight">Dreams a Reality!</span></h1>
          <p className="learn-more-intro">
            Get personalized plans and resources to launch your startup, project, or business today.
          </p>
          <p className="learn-more-cta">
            Start now—your journey begins here!
          </p>
          <div className="hero-buttons">
            <button className="hero-button signup-button" onClick={handleSignUp}>Sign Up</button>
            <Link to="/about-us" className="hero-button about-button">About Us</Link>
          </div>
        </section>

        <section className="how-it-works">
          <h2 className="section-title">Your Journey to Success</h2>
          <motion.div 
            ref={ref}
            className="steps-container"
            variants={containerVariants}
            initial="hidden"
            animate={controls}
          >
            {steps.map((step, index) => (
              <motion.div
                key={index}
                className="step-card"
                variants={cardVariants}
                whileHover={{ 
                  scale: 1.05, 
                  boxShadow: "0 8px 16px rgba(32, 201, 151, 0.3)",
                  transition: { duration: 0.3 }
                }}
              >
                <div className="step-icon">{step.icon}</div>
                <h3 className="step-title">{step.title}</h3>
                <p className="step-description">{step.description}</p>
              </motion.div>
            ))}
          </motion.div>
        </section>

        <Services />

        <div className="cta-container">
          <SignedIn>
            <div className="upgrade-button-wrapper">
              <Link to="/membership" className="upgrade-button">Upgrade to Premium</Link>
            </div>
          </SignedIn>
          <SignedOut>
            <div className="hero-buttons">
              <button className="hero-button signup-button" onClick={handleSignUp}>Sign Up</button>
            </div>
          </SignedOut>
        </div>

        {showUserAgreement && (
          <UserAgreement
            onSubmit={handleUserAgreementSubmit}
            onCancel={handleUserAgreementCancel}
          />
        )}
      </div>
    );
};

export default LearnMore;
