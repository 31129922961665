import React, { useState, useEffect, useRef } from 'react';
import { useUser, useSession } from '@clerk/clerk-react';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import config from '../config';
import './css/MarketingAdvisor.css';

const API_BASE_URL = config.API_BASE_URL;

interface Message {
  id: string;
  content: string;
  role: 'user' | 'assistant';
}

const MarketingAdvisor: React.FC = () => {
  const { isLoaded: isUserLoaded, user } = useUser();
  const { isLoaded: isSessionLoaded, session } = useSession();
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Add initial AI message
    const initialMessage: Message = {
      id: 'initial',
      content: `Welcome to the AI Marketing Advisor! I'm here to help you develop effective marketing strategies for your business. Please note that while I provide general marketing advice based on best practices and trends, this should not be considered as financial advice. Always consult with qualified professionals for financial decisions related to your business. **How can I help you with your marketing strategy today?**`,
      role: 'assistant'
    };
    setMessages([initialMessage]);
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [messages]);

  const handleSendMessage = async () => {
    if (!inputMessage.trim()) return;

    const newUserMessage: Message = {
      id: Date.now().toString(),
      content: inputMessage,
      role: 'user',
    };

    setMessages(prevMessages => [...prevMessages, newUserMessage]);
    setInputMessage('');
    setIsLoading(true);
    setError(null);

    try {
      const token = await session?.getToken();
      const conversationHistory = messages.map(msg => ({
        role: msg.role,
        content: msg.content
      }));

      const response = await axios.post(
        `${API_BASE_URL}/api/ideas/marketing-advisor`,
        { 
          message: inputMessage,
          conversationHistory: conversationHistory
        },
        { 
          headers: { 
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          } 
        }
      );

      const aiResponse: Message = {
        id: (Date.now() + 1).toString(),
        content: response.data.marketingAdvice,
        role: 'assistant',
      };

      setMessages(prevMessages => [...prevMessages, aiResponse]);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response?.status === 404) {
          setError('User not found. Please try logging in again.');
        } else if (error.response?.status === 403) {
          setError('This feature is only available for premium users.');
        } else {
          setError('Failed to get AI response. Please try again.');
        }
      } else {
        setError('An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  if (!isUserLoaded || !isSessionLoaded) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <div>Please sign in to access the Marketing Advisor.</div>;
  }

  return (
    <div className="marketing-advisor-page">
      <div className="marketing-advisor-container">
        <h1 className="header-ai">AI Marketing Advisor</h1>
        <p>Let's develop your marketing strategy together!</p>
        <div className="chat-container" ref={chatContainerRef}>
          {messages.map((message) => (
            <div key={message.id} className={`message ${message.role}`}>
              {message.role === 'assistant' ? (
                <ReactMarkdown>{message.content}</ReactMarkdown>
              ) : (
                message.content
              )}
            </div>
          ))}
          {isLoading && <div className="message assistant">AI is typing...</div>}
        </div>
        <div className="input-container">
          <input
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Ask about marketing strategies..."
          />
          <button onClick={handleSendMessage} disabled={isLoading}>Send</button>
        </div>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};

export default MarketingAdvisor;
