import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const BodyClassManager: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        // Set body class based on the current route
        document.body.className = location.pathname.startsWith('/forum') ? 'forum' : 'home';
    }, [location]);

    return null; // This component does not render anything
};

export default BodyClassManager;