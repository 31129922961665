import React, { useState, useEffect } from 'react';
import { useSession } from '@clerk/clerk-react';
import { useLocation } from 'react-router-dom';
import '../../css/ServicePlan.css';
import axios from 'axios';
import config from '../../config';
import { saveStepData } from '../../utils/localStorageUtils';

const API_BASE_URL = config.API_BASE_URL;

interface TargetMarketProps {
    membershipType: string;
    businessIdea: string;
    businessName: string;
    missionStatement: string;
    location: string;
    setLocation: (location: string) => void;
    onNextStep: () => void;
    isPremiumGeneration?: boolean; // Add this prop
}

const TargetMarket: React.FC<TargetMarketProps> = ({ 
    membershipType, 
    businessIdea, 
    businessName, 
    missionStatement,
    location,
    onNextStep,
}) => {    const { session } = useSession();
    const Location = useLocation();
    const [targetDemographic, setTargetDemographic] = useState('');
    const [competitors, setCompetitors] = useState<Array<{ name: string, url: string }>>([]);
    const [newCompetitorName, setNewCompetitorName] = useState('');
    const [newCompetitorUrl, setNewCompetitorUrl] = useState('');
    const [uniqueSellingPoint, setUniqueSellingPoint] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);
    const { isPremiumGeneration } = Location.state as { membershipType: string, isPremiumGeneration: boolean };
    const [suggestedDemographics, setSuggestedDemographics] = useState<string[]>([]);
    const [suggestedUSPs, setSuggestedUSPs] = useState<string[]>([]);

    const isPremiumFeature = membershipType === 'premium' || isPremiumGeneration;

    useEffect(() => {
        if (isPremiumFeature) {
            generateSuggestions();
        }
    }, [isPremiumFeature]);

    useEffect(() => {
        const targetMarketData = {
            targetDemographic,
            competitors,
            uniqueSellingPoint
        };
        saveStepData('targetMarket', targetMarketData);
    }, [targetDemographic, competitors, uniqueSellingPoint]);

    const generateSuggestions = async () => {
        if (!session) {
            setError('There is no active session');
            return;
        }

        setLoading(true);
        try {
            const token = await session.getToken();
            const response = await axios.post(
                `${API_BASE_URL}/api/ideas/identify-target-market`,
                { businessIdea, businessName, missionStatement, location },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'ngrok-skip-browser-warning': 'true'
                    }
                }
            );
            const { targetDemographic, competitors: suggestedCompetitors, uniqueSellingPoint } = response.data;
            setSuggestedDemographics([targetDemographic]);
            setSuggestedUSPs([uniqueSellingPoint]);
            
            // Automatically add suggested competitors to the main list
            setCompetitors(prevCompetitors => {
                const newCompetitors = suggestedCompetitors.filter(
                    (suggested: { name: string }) => !prevCompetitors.some(existing => existing.name !== suggested.name)
                );
                return [...prevCompetitors, ...newCompetitors];
            });
        } catch (error) {
            console.error('Error generating suggestions');
            setError('Failed to generate suggestions. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const addCompetitor = () => {
        if (newCompetitorName && newCompetitorUrl) {
            const formattedUrl = newCompetitorUrl.startsWith('http://') || newCompetitorUrl.startsWith('https://')
                ? newCompetitorUrl
                : `https://${newCompetitorUrl}`;
            setCompetitors([...competitors, { name: newCompetitorName, url: formattedUrl }]);
            setNewCompetitorName('');
            setNewCompetitorUrl('');
        }
    };

    const areInputsValid = () => {
        return location.length > 0 &&
               targetDemographic.length > 0 &&
               competitors.length > 0 &&
               uniqueSellingPoint.length > 0;
    };

    if (error) return <div className="error-message">{error}</div>;
    if (loading) return <div className="loading-message">Loading...</div>;

    return (
        <div className="target-market-container">
            <div className="step-header">Target Market Identification</div>

            <div className="custom-name-input">
                <div className="classic-section-header">Target Demographic</div>
                <input
                    type="text"
                    value={targetDemographic}
                    onChange={(e) => setTargetDemographic(e.target.value)}
                    placeholder="Enter target demographic"
                />
                {(membershipType === 'premium' || isPremiumGeneration) && suggestedDemographics.length > 0 && (
                    <div className="name-options">
                        {suggestedDemographics.map((demo, index) => (
                            <button
                                key={index}
                                onClick={() => setTargetDemographic(demo)}
                                className={`name-option ${targetDemographic !== demo ? 'selected' : ''}`}
                            >
                                {demo}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <div className="custom-name-input">
                <div className="classic-section-header">Competitors</div>
                <div className="competitor-inputs">
                    <input
                        type="text"
                        value={newCompetitorName}
                        onChange={(e) => setNewCompetitorName(e.target.value)}
                        placeholder="Competitor name"
                        className="competitor-input"
                    />
                    <input
                        type="text"
                        value={newCompetitorUrl}
                        onChange={(e) => setNewCompetitorUrl(e.target.value)}
                        placeholder="Competitor URL"
                        className="competitor-input"
                    />
                    <button 
                        onClick={addCompetitor} 
                        className="add-competitor-button"
                        disabled={!newCompetitorName || !newCompetitorUrl}
                    >
                        Add
                    </button>
                </div>
                <div className="name-options">
                    {competitors.map((competitor, index) => (
                        <a
                            key={index}
                            href={competitor.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="name-option"
                        >
                            {competitor.name}
                        </a>
                    ))}
                </div>
            </div>

            <div className="custom-name-input">
                <div className="classic-section-header">Unique Selling Point</div>
                <textarea
                    id="uniqueSellingPoint"
                    value={uniqueSellingPoint}
                    onChange={(e) => setUniqueSellingPoint(e.target.value)}
                    placeholder="What makes your business stand out?"
                    className="unique-selling-point-input"
                />
                {(membershipType === 'premium' || isPremiumGeneration) && suggestedUSPs.length > 0 && (
                    <div className="name-options">
                        {suggestedUSPs.map((usp, index) => (
                            <button
                                key={index}
                                onClick={() => setUniqueSellingPoint(usp)}
                                className={`name-option ${uniqueSellingPoint !== usp ? 'selected' : ''}`}
                            >
                                {usp}
                            </button>
                        ))}
                    </div>
                )}
            </div>

            <button 
                onClick={onNextStep} 
                className="next-step-button" 
                disabled={!areInputsValid()}
            >
                Continue
            </button>
        </div>
    );
};

export default TargetMarket;

