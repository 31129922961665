import { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons';
import { useSession } from "@clerk/clerk-react";
import axios from 'axios';
import '../css/results.css';
import SavePlanModal from './SavePlanModal';
import config from '../config';
import LoadingPopup from './LoadingPopup';

const API_BASE_URL = config.API_BASE_URL;

interface Product {
    image: string;
    title: string;
    price: string;
    link: string;
}

const Results: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoaded: isSessionLoaded, session } = useSession();
    const [showSaveModal, setShowSaveModal] = useState(false);
    const [businessPlan, setBusinessPlan] = useState('');
    const [userMembership, setUserMembership] = useState<string>('free');
    const [error, setError] = useState<string | null>(null);
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userInput, setUserInput] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [productSearch, setProductSearch] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    const [products, setProducts] = useState<Product[]>([]);
    const [description, setDescription] = useState<string>('');

    useEffect(() => {
        setDescription(localStorage.getItem('businessDescription') || '');
    }, []);

    useEffect(() => {
        if (location.state && location.state.products) {
            setProducts(location.state.products);
        }
    }, [location.state]);

    const { businessIdea = '', tier = '', budget = '' } = location.state || {};

    useEffect(() => {
        const fetchUserMembership = () => {
            if (isSessionLoaded && session) {
                const membershipType = session.user.publicMetadata.membershipType as string;
                setUserMembership(membershipType || 'free');
            }
        };
    
        fetchUserMembership();
    }, [isSessionLoaded, session]);

    useEffect(() => {
        const fetchBusinessPlan = async () => {
            if (isSessionLoaded && session) {
                const membershipType = session.user.publicMetadata.membershipType as string;
                setUserMembership(membershipType || 'free');
        
                if (membershipType === 'premium') {
                    const storedPlan = sessionStorage.getItem('businessPlan');
                    const storedIdea = sessionStorage.getItem('businessIdea');
                    const storedTier = sessionStorage.getItem('tier');
                    const storedBudget = sessionStorage.getItem('budget');
    
                    if (storedPlan && storedIdea && storedTier && storedBudget) {
                        setBusinessPlan(storedPlan);
                        setLoading(false);
                        return;
                    }
    
                    if (businessIdea && tier && budget) {
                        try {
                            setLoading(true);
                            const token = await session.getToken();
                            const response = await axios.post(`${API_BASE_URL}/api/ideas/generate-business-plan`, {
                                businessIdea,
                                tier,
                                budget, 
                                description
                            }, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    'ngrok-skip-browser-warning': 'true'
                                }
                            });
                            const generatedPlan = response.data.businessPlan;
                            setBusinessPlan(generatedPlan);
                            sessionStorage.setItem('businessPlan', generatedPlan);
                            sessionStorage.setItem('businessIdea', businessIdea);
                            sessionStorage.setItem('tier', tier);
                            sessionStorage.setItem('budget', budget);
                        } catch (error) {
                            if (axios.isAxiosError(error) && error.response) {
                                setError(`Failed to generate business plan: ${error.response.data.error}. ${error.response.data.details || ''}`);
                            } else {
                                setError('Failed to generate business plan. Please try again.');
                            }
                        } finally {
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                } else {
                    setLoading(false);
                }
            }
        };
    
        fetchBusinessPlan();
    }, [isSessionLoaded, session, businessIdea, tier, budget, description]);
    
    useEffect(() => {
        return () => {
            sessionStorage.removeItem('businessPlan');
            sessionStorage.removeItem('businessIdea');
            sessionStorage.removeItem('tier');
            sessionStorage.removeItem('budget');
        };
    }, []);

    const handleProductSearch = async () => {
        if (!productSearch.trim() || userMembership !== 'premium') return;
        setIsSearching(true);
        try {
            const token = await session?.getToken();
            const response = await axios.post(`${API_BASE_URL}/api/ideas/search-product`, 
                { productName: productSearch, businessIdea: businessIdea },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            if (response.data.products && Array.isArray(response.data.products)) {
                setProducts(prevProducts => [...prevProducts, ...response.data.products]);
            }
            setProductSearch('');
        } catch (error) {
            setError('Failed to search for product. Please try again.');
        } finally {
            setIsSearching(false);
        }
    };

    const savePlan = async (planName: string) => {
        try {
            const token = await session?.getToken();
            await axios.post(`${API_BASE_URL}/api/users/auth/save-plan`, {
                planName,
                products,
                businessPlan
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
            setShowSaveModal(false);
            alert('Plan saved successfully!');
            navigate('/profile');
        } catch (error) {
            alert('Failed to save plan. Please try again.');
        }
    };

    const handleEditToggle = () => {
        setIsEditing(!isEditing);
    };

    const handleBusinessPlanChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setBusinessPlan(e.target.value);
    };

    const handleUserInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUserInput(e.target.value);
    };

    const handleUserInputSubmit = async (e?: React.KeyboardEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement>) => {
        if (e && 'key' in e && e.key !== 'Enter') {
            return;
        }
        e?.preventDefault();

        setIsLoading(true);
        try {
            if (!session) {
                throw new Error('No active session');
            }

            const token = await session.getToken();
            if (!token) {
                throw new Error('No token available');
            }

            const membershipType = session.user.publicMetadata.membershipType as string;

            const response = await axios.post(`${API_BASE_URL}/api/ideas/business-plan-update`, {
                businessPlan,
                userInput,
                membershipType
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            const updatedPlan = response.data.updatedBusinessPlan;
            setBusinessPlan(updatedPlan);
            sessionStorage.setItem('businessPlan', updatedPlan);
            setUserInput('');
        } catch (error) {
            if (axios.isAxiosError(error) && error.response) {
                setError(`Failed to update business plan: ${error.response.data.error || error.message}`);
            } else {
                setError('Failed to update business plan. Please try again.');
            }
        } finally {
            setIsLoading(false);
        }
    };

    if (error) {
        return <div>Error: {error}</div>;
    }

    const formatBusinessPlan = (text: string) => {
        const formattedText = text
            .replace(/\n/g, '<br />')
            .replace(/(\d+\.\s)/g, '<strong>$1</strong>')
            .replace(/-\s/g, '<br />&nbsp;&nbsp;&nbsp;&nbsp;- ');
    
        return formattedText;
    };

    return (
        <div className="Results">
            {isLoading && <LoadingPopup message="Updating your business plan..." />}
            <div className="results-header">
                <h1>Suggested Products</h1>
                {userMembership === 'premium' && (
                    <button onClick={() => setShowSaveModal(true)} className="save-button">
                        Save <FontAwesomeIcon icon={faSave} />
                    </button>
                )}
            </div>
            <div className="product-grid">
                {products.length > 0 ? (
                    products.map((product: Product, index: number) => (
                        <div key={index} className="product-card">
                            <img src={product.image} alt={product.title} className="product-image" />
                            <h2 className="product-title">{product.title}</h2>
                            <p className="product-price">{product.price}</p>
                            <a href={product.link} className="product-link" target="_blank" rel="noopener noreferrer">
                                View Product
                            </a>
                        </div>
                    ))
                ) : (
                    <p>No products found.</p>
                )}
            </div>
            {userMembership === 'premium' && (
                <div>
                    <div className="regen-business-plan-text">Any product you want to add?</div>
                    <div className="new-product-input-container">
                        <input 
                            type="text" 
                            value={productSearch} 
                            onChange={(e) => setProductSearch(e.target.value)} 
                            placeholder="Search for a product" 
                            className="new-product-input" 
                        />
                        <button 
                            className="submit-new-product-button" 
                            onClick={handleProductSearch}
                            disabled={isSearching}
                        >
                            {isSearching ? 'Searching...' : 'Search'}
                        </button>
                    </div>
                </div>
                
            )}
            {userMembership === 'premium' && (
                <div className="business-plan">
                    <h2>Sample Business Plan:</h2>
                    {loading ? (
                        <LoadingPopup message="Loading your business plan..." />
                    ) : (
                        <>
                            {isEditing ? (
                                <textarea
                                    value={businessPlan}
                                    onChange={handleBusinessPlanChange}
                                    rows={10}
                                    className="business-plan-textarea"
                                    style={{ width: '100%', height: '100%' }}
                                />
                            ) : (
                                <div 
                                    className="business-plan-text" 
                                    dangerouslySetInnerHTML={{ __html: formatBusinessPlan(businessPlan) }} 
                                />
                            )}
                            <FontAwesomeIcon 
                                icon={isEditing ? faSave : faEdit} 
                                onClick={handleEditToggle} 
                                className="edit-icon" 
                                style={{ color: 'blue', cursor: 'pointer', position: 'absolute', top: '10px', right: '10px' }} 
                            />
                        </>
                    )}
                </div>
            )}
            {userMembership === 'premium' && (
                <div>
                    <div className="regen-business-plan-text">Have details you want to include in your business plan?</div>
                    <div className="business-plan-input-container">
                        <input 
                            type="text" 
                            value={userInput} 
                            onChange={handleUserInputChange} 
                            onKeyDown={handleUserInputSubmit} 
                            placeholder="Type your details here and hit Enter" 
                            className="business-plan-input" 
                        />
                        <button 
                            className="submit-business-plan-button" 
                            onClick={handleUserInputSubmit}
                        >
                            Submit
                        </button>
                    </div>
                </div>
            )}
            {userMembership !== 'premium' && (
                <div className="upgrade-message">
                    Want a detailed business plan? <Link to="/membership">Upgrade to Premium!</Link>
                </div>
            )}
            {showSaveModal && (
                <SavePlanModal
                    onSave={savePlan}
                    onClose={() => setShowSaveModal(false)}
                    businessName={''}
                    className="top-modal"
                />
            )}
        </div>
    );
};

export default Results;