import React, { useState, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import config from '../config';
import { useSession } from '@clerk/clerk-react';
import LoadingPopup from './LoadingPopup';
import '../css/preliminarySubmission.css';

const API_BASE_URL = config.API_BASE_URL;

const PreliminarySubmission: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { productSuggestions, businessIdea, tier, budget } = location.state || { productSuggestions: [], businessIdea: '', tier: '', budget: 1000 };
    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
    const [error, setError] = useState<string | null>(null);
    const { session } = useSession();
    const [showLoading, setShowLoading] = useState(false);

    const validProductSuggestions = useMemo(() => 
        productSuggestions.filter((product: string) => product.trim() !== ''),
        [productSuggestions]
    );

    const handleCheckboxChange = (product: string) => {
        setSelectedProducts(prev =>
            prev.includes(product) ? prev.filter(item => item !== product) : [...prev, product]
        );
    };

    const handleSubmit = async () => {
        if (!session) {
            setError('Session is not available. Please log in again.');
            return;
        }
    
        if (!businessIdea || !tier || !Array.isArray(selectedProducts) || typeof budget !== 'number') {
            setError('Please fill in all fields correctly.');
            return;
        }
    
        setShowLoading(true);
    
        try {
            const token = await session.getToken();
            const uncheckedProducts = validProductSuggestions.filter((product: string) => !selectedProducts.includes(product));
    
            const response = await axios.post(`${API_BASE_URL}/api/ideas/submit`, {
                businessIdea,
                tier,
                products: selectedProducts,
                budget,
                uncheckedProducts
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                }
            });
    
            navigate('/results', { 
                state: { 
                    products: response.data.products,
                    businessIdea,
                    tier,
                    budget
                } 
            });
        } catch (error) {
            setError('Failed to fetch finalized product suggestions. Please try again.');
        } finally {
            setShowLoading(false);
        }
    };

    return (
        <div className="container">
            {showLoading && <LoadingPopup message="Submitting your selections..." />}
            <h1>Initial Suggested Products</h1>
            <h2>Check which products you have</h2>
            {error && <p className="error-message">{error}</p>}
            <div className="product-list">
                {validProductSuggestions.length > 0 ? (
                    validProductSuggestions.map((product: string, index: number) => (
                        <div key={index} className="product-item">
                            <input
                                type="checkbox"
                                id={`product-${index}`}
                                value={product}
                                onChange={() => handleCheckboxChange(product)}
                                checked={selectedProducts.includes(product)}
                            />
                            <label htmlFor={`product-${index}`}>{product}</label>
                        </div>
                    ))
                ) : (
                    <p>No valid product suggestions available.</p>
                )}
            </div>
            <button onClick={handleSubmit} className="submit-button1-preliminary">Submit Items</button>
        </div>
    );
};

export default PreliminarySubmission;